import { useState} from "react";

import classes from './Password.module.css';

const Password = (props) => {
    const [password, setPassword] = useState("");
    const [toggleHide, setToggleHide] = useState(true); 

    let imgSrc = "./hide.png"
    if (toggleHide) {
        imgSrc = "./unhide.png"
    };
    
  
//-------------------------------------------------------------------------
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        props.onChangePassword(event.target.value);
    }
    const toggleHandler = () => {
        setToggleHide(!toggleHide);
    }
//------------------------------------------------------------------------------------ 
    return (
    <div>
        <div className={classes.labPassword}>
            <label htmlFor='password'>{props.lblPassword}</label>
        </div>
        <div className={classes.pswdContainer}>
            <input 
                className={classes.inpPassword} 
                type={toggleHide ? "password" : "text"} 
                id='password' 
                onChange={handlePasswordChange} 
                value={password} 
                autoFocus = {props.autoFocus}
            />
            <img src={imgSrc} onClick={toggleHandler} alt="hide" className={classes.imgHideUnhide}/>
        </div>
    </div>
    );
};

export default Password;