import React, { useState , useEffect, useContext, useCallback } from "react";
import AuthContext from "../../store/auth-context";

import Stats from "./Stats.js";
import SelectUI from "./SelectUI.js";
import SrcDataUI from "./SrcDataUI.js";
import BestDataUI from "./BestDataUI.js";
import NextIssue from "./NextIssue.js";
import PrevIssue from "./PrevIssue.js";
import AddressRepresentation from "./AddressRepresentation.js";
import Mapping from "./Mapping.js";
import Decision from "./Decision.js";
import Footer from "./Footer.js";

import "./Issues.css";

const lblUpdate_multi = {"nl":"Update","fr":"Update","de":"Update"};
const lblMapWarning_multi = {'nl':"Er is meer dan 1 postcode: kies de correcte postcode !", 'fr':"Il y a plus qu'un code postal: choisissez le bon code postal !", 'de':"Es gibt mehr als 1 Postleitzahl: Wählen Sie die richtige Postleitzahl !"};
const lblRectifyPostcodeBest_multi = {'nl':"Vergeet niet de postcode bij te werken in BEST !", 'fr':"N'oubliez pas de mettre à jour le code postal dans BEST !", 'de':"Vergessen Sie nicht, die Postleitzahl in BEST zu aktualisieren!"};
const lblRectifyPostcodeSrc_multi = {'nl':"Vergeet niet de postcode bij te werken in de bron !", 'fr':"N'oubliez pas de mettre à jour le code postal dans la source !", 'de':"Vergessen Sie nicht, die Postleitzahl in der Quelle zu aktualisieren!"};
const alertMakeDecision_multi = {"nl":"Maak een keuze uit de 3 mogelijke beslissingen","fr":"Choisissez parmi les 3 décisions possibles", "de":"Wählen Sie aus den 3 möglichen Entscheidungen"};
const hdrDecision_multi = {"nl":"Beslissing van de gemeente","fr":"Décision de la commune","de":"Entscheidung der Gemeinde"};
const concurrencyMsg_multi = {"nl": "Het probleem is eerder vandaag bijgewerkt door ", "fr": "Le problème a été mis à jour plus tôt aujourd'hui par ", "de": "Das Problem wurde früher heute aktualisiert von "};
const noMatchingIssues_multi = {"nl": "De selectie levert geen resultaat op.", "fr": "La sélection ne donne aucun résultat.", "de": "Die Auswahl ergibt kein Ergebnis."};

const createAuthenticationString = (uid, token) => {
    let authString = uid + ':' + token;
    let buff = new Buffer.from(authString, 'utf8');
    let authString64 = buff.toString('base64');
    let auth64 = 'Basic ' + authString64;
    //console.log(authString, authString64, auth64); 
    return auth64 ;
}

const isAlpha = ch => {
	return /^[A-Za-z]$/.test(ch)
};

const get_BEST_nrs = (issue, mapNumbers) => {
	console.log("get_BEST_nrs");
	let newHs = '';
	let newBx = '';
	let hs2 = issue.hs2;
	let bx2 = issue.bx2;
	if (bx2 === "EMPTY") {
		bx2 = "empty";
	}
	let bx = issue.bx;

	if (issue.warning === "D2" && issue.treated === "0") {
		const idS = issue.idS;
		const lst = idS.split('_');
		const idSnoVersion = lst[0] + '_' + lst[1];
		console.log("****", idS, idSnoVersion, hs2, bx2, issue.hs, issue.bx);
		if (idSnoVersion in mapNumbers) {
			console.log("****", idS, idSnoVersion, hs2, bx2, issue.hs, issue.bx, mapNumbers[idSnoVersion][hs2]);
			if (mapNumbers[idSnoVersion][hs2].includes(bx2)) {
				if (isAlpha(hs2.slice(-1)) && hs2.slice(-1) !== bx.slice(0,1) && hs2.slice(0,-1) in mapNumbers[idSnoVersion]) {
					//avoid to show 2A as BEST house nr, if 2 is also a BEST house nr and the National Register's house number is 2 and the index does not start with A
					newHs = hs2.slice(0,-1);
					newBx = mapNumbers[idSnoVersion][newHs][0];
				} else {
				//show a combination hs and bx that exists in BEST
				newHs = issue.hs2;
				newBx = issue.bx2;
				}
			} else {
				//you don't want to show a combination hs and bx that does not exist in BEST
				if (isAlpha(hs2.slice(-1)) && hs2.slice(-1) !== bx.slice(0,1) && hs2.slice(0,-1) in mapNumbers[idSnoVersion]) {
					newHs = hs2.slice(0,-1);
					newBx = mapNumbers[idSnoVersion][newHs][0];
				} else {
					newHs = issue.hs2;
					newBx = mapNumbers[idSnoVersion][hs2][0];
				};
			};
		}
	} else {
		//normal case if warning <> D2 or if issue has been treated
		newHs = issue.hs2;
		newBx = issue.bx2;
	};
	return {myHs: newHs, myBx: newBx} ; 
};

//---------------------------------------------------------------------------------------------------------------------------
function Issues() {
	console.log("RENDER Issues.js");

	const authCtx = useContext(AuthContext);

	const domainBackend = authCtx.domainBackend;
	const lan = authCtx.language;
	const isAdmin = authCtx.isAdmin;
	const isLoggedIn = authCtx.isLoggedIn;
	const Nis = authCtx.NIS;
	//const idM = authCtx.idM;
	const email = authCtx.email;
	const token = authCtx.token;
	const SRC_multi = {"nl":authCtx.src_nl, "fr":authCtx.src_fr, "de": authCtx.src_de};
	const blockUpdates = authCtx.isInIncidentState || isAdmin;
	const setPage = authCtx.setPage;
	setPage("I"); //cfr Header.js
	//const streetSplitLimit = authCtx.streetSplitLimit;
	//const loadMunicipalityIssues = authCtx.loadMunicipalityIssues;
	const authenticationString = createAuthenticationString(email, token);

	const issues = authCtx.issues;
	const setIssues = authCtx.setIssues;
	//console.log("ISSUES", issues);
    const mapStreetnamesNL = authCtx.mapStreetnamesNL;//the list of streetnames (with idS) as shown in the mapping streetname list
    const mapStreetnamesFR = authCtx.mapStreetnamesFR;//the list of streetnames (with idS) as shown in the mapping streetname list
    const mapStreetnamesDE = authCtx.mapStreetnamesDE;//the list of streetnames (with idS) as shown in the mapping streetname list
    const streetIds = authCtx.streetIds;//a dictionary idS:streetname (why ? to fill in S with one of the official streetnames)
    const streetnameDic = authCtx.streetnameDic;//multi-language dic full {idS:lan:name}
    const streetObjIdToIdS = authCtx.streetObjIdToIdS;

	const mapNumbers = authCtx.mapNumbers;

	const [issue, setIssue] = useState({});
	const [issueIx, setIssueIx] = useState(0); //pointer to the current issue 

	console.log("ISSUE", issue);

	const [filter1, setFilter1] = useState("0");   //treated: in ["0", "1"], 0 means open issues
	const [filter2, setFilter2] = useState("All"); //warning: in ["All", "B1", "B2", "B3", "C1", "C2", "C3", "D1", "D2", "D3", "Z2", "Z3"]

	const [mapPs, setMapPs] = useState([]); //the list of postcodes based on the merger of the SRC postcode and the list of BEST postcodes as given in the issue
	const [mapWarning, setMapWarning] = useState({"nl":"", "fr":"", "de":""}); //warning message when there is more than 1 postcode to choose from
	//const [mapNumbers, setMapNumbers] = useState({}); // dic {idS:{}}
	const [mapStreetHouseNumbers, setMapStreetHouseNumbers] = useState([]); // list of house numbers for the given mapIdS
	const [mapBoxNumbers, setMapBoxNumbers] = useState([]); // list of box numbers for the given mapHs

	const [mapP, setMapP] = useState("tbd"); //the selected postcode in the mapping list box
	const [mapIdS, setMapIdS] = useState("tbd"); //the selected idS in the mapping streetname list (full identifier)
	const [mapHs, setMapHs] = useState("tbd"); //the selected house number in the mapping listbox
	const [mapBx, setMapBx] = useState("tbd"); //the selected box number in the mapping listbox
	const [mapIdA, setMapIdA] = useState("tbd"); //the idA shown in the text field

	const [decision, setDecision] = useState("0"); //the decision corresponding to the selected radio button
	const [comment, setComment] = useState(""); //value of the text area under the decision (only useful for decisions 2 and 3)
	const [resultUpdate, setResultUpdate] = useState(""); //shows msg "update ok" after hitting the update button
	const [warningPostcode, setWarningPostcode] = useState(""); //warning postcode in decision box
	const [step, setStep] = useState(1); //step for scrolling through issue list
	const [displayP2, setDisplayP2] = useState(""); //display P2 in BEST data part, possibly with BPOST correction included


	const lblUpdate = lblUpdate_multi[lan] ;
	const lblRectifyPostcodeBest = lblRectifyPostcodeBest_multi[lan] ;
	const lblRectifyPostcodeSrc = lblRectifyPostcodeSrc_multi[lan] ;
	const alertMakeDecision = alertMakeDecision_multi[lan] ;
	const SRC = SRC_multi[lan];
	const hdrDecision = hdrDecision_multi[lan];
	const concurrencyMsg = concurrencyMsg_multi[lan];
	const noMatchingIssues = noMatchingIssues_multi[lan];
	//this street idf without version is used in mapNumbers - why ? 
	//due to version differences between Flemish streetnames and addresses , mapNumbers has a street id without version
	let mapIdSnoVersion = "";
	if (mapIdS && mapIdS !== "tbd") {
		const lst = mapIdS.split('_');
		mapIdSnoVersion = lst[0] + '_' + lst[1];
	};

	//if (warningPostcode && (issue.P3 === issue.P)) {setWarningPostcode(lblRectifyPostcodeBest)};
	//if (warningPostcode && (issue.P3 !== issue.P)) {setWarningPostcode(lblRectifyPostcodeSrc)};


	const cntIssues = useCallback((treated, warning) => {
		let cnt = 0;
		issues.forEach(function(element) { 
			if (element.treated === treated) {
				if (warning === "All") {
					cnt = cnt + 1;
				} else {
					if (element.warning === warning) {
						cnt = cnt + 1;
					}
				}
			}
		});
		return cnt;	
	}, [issues]);

	function changeStep(evt) {
		//console.log("changeStep", step, "=>", evt.target.value,issueIx);
		setStep(parseInt(evt.target.value));
	}

	function handlePrevious() {
		if (issueIx - step >= 0) {
			for (let i = issueIx - step ; i >= 0 ; i--){
				if ((filter2 === "All" || issues[i].warning === filter2) && issues[i].treated === filter1 &&
				    (issues[i].action !== "02" && issues[i].action !== "03")) {
					setIssueIx(i);
					setIssue(issues[i]);
					setDecision("0"); //uncheck radio button
					break;
				}
			}
		}
	}

	function handleNext() {
		if (issueIx + step < issues.length) {
			for (let i = issueIx + step ; i < issues.length ; i++){
				if ((filter2 === "All" || issues[i].warning === filter2) && issues[i].treated === filter1 &&
				(issues[i].action !== "02" && issues[i].action !== "03")) {
					setIssueIx(i);
					setIssue(issues[i]);
					setDecision("0"); //uncheck radio button
					break;
				}
			}
		}
	}

	function updFilter1(evt){
		if (cntIssues(evt.target.value, filter2) > 0) {
			setFilter1(evt.target.value);
			setDecision("0"); //uncheck radio button
		} else {
			alert(noMatchingIssues);
		};
	}
    function updFilter2(evt){
		if (cntIssues(filter1, evt.target.value) > 0) {
			setFilter2(evt.target.value);
			setDecision("0"); //uncheck radio button
		} else {
			alert(noMatchingIssues);
		};
	}

    function handleMapIdS(evt) {
		//console.log("handleMapIdS", evt.target.value);
		const idS = evt.target.value;
		setMapIdS(idS);
		let idSnoVersion = ""; //this is used in mapNumbers - why ? due to version differences between Flemish streetnames and addresses 
		if (idS !== "tbd") {
			const lst = idS.split('_');
			idSnoVersion = lst[0] + '_' + lst[1];
		};
		var hs = "tbd";
		var bx = "tbd";
		var hs_bx = hs.concat("_|_").concat(bx);
		var idA = "tbd";
		if (idSnoVersion in mapNumbers) {
			setMapStreetHouseNumbers(mapNumbers[idSnoVersion]["hs"]);
			hs = mapNumbers[idSnoVersion]["hs"][0];
			bx = mapNumbers[idSnoVersion][hs][0];
			hs_bx = hs.concat("_|_").concat(bx);
			idA = mapNumbers[idSnoVersion][hs_bx];
			setMapBoxNumbers(mapNumbers[idSnoVersion][hs]);
		} else {
			setMapStreetHouseNumbers([]);
			setMapBoxNumbers([]);
		}
		setMapHs(hs);
		setMapBx(bx);
		setMapIdA(idA);
	}

	function handleMapP(evt) {
		//console.log("handleMapP", evt.target.value);
		setMapP(evt.target.value);
	}

	function handleMapHs(evt) {
		//console.log("handleMapHs", evt.target.value);
		const hs = evt.target.value;
		const bx = mapNumbers[mapIdSnoVersion][hs][0];
		const hs_bx = hs.concat("_|_").concat(bx);
		const idA = mapNumbers[mapIdSnoVersion][hs_bx];

		setMapHs(hs);
		setMapBoxNumbers(mapNumbers[mapIdSnoVersion][hs]);
		setMapBx(bx);
		setMapIdA(idA);
	}

	function handleMapBx(evt) {
		//console.log("handleMapBx", evt.target.value);
		const hs = mapHs;
		const bx = evt.target.value;
		const hs_bx = hs.concat("_|_").concat(bx);
		const idA = mapNumbers[mapIdSnoVersion][hs_bx];

		setMapBx(bx);
		setMapIdA(idA);
	}

	function handleDecision(evt) {
		setDecision(evt.target.value);
		if (evt.target.value === "1") {
			setComment("");
		}
	}
	function handleComment(evt) {
		setComment(evt.target.value);
	}
//----------------------------------------------------------------------------------
	async function updateIssue(evt) {
		//part of updating an issue consists of updating the statistics
		evt.preventDefault();

		//const updatedIssue = Object.assign({}, issue);
		var decisionPossibilities = ["1", "2", "3", "4"];

		if (! decisionPossibilities.includes(decision)) {
			alert(alertMakeDecision);
			return;
		};

		issue.treated = "1";
		issue.comment = comment;

		if (decision === "1") {
			issue.idA = mapIdA;
			//const oldAction = issue.action;
			issue.action = '20';
			//'mun.decision: ok – manually mapped';
			issue.P3 = mapP; 
			//updatedIssue.idS = mapIdS.split('_')[1]; //issue.idS only contains the objId
			issue.idS = mapIdS;
			issue.Snl2 = streetIds[mapIdS];
			issue.Sfr2 = streetIds[mapIdS];
			issue.Sde2 = streetIds[mapIdS];
			console.log("UPDATE", mapIdS, streetIds[mapIdS]);
			issue.hs2 = mapHs;
			if (mapBx === 'empty') { issue.bx2 = ""}
			else {issue.bx2 = mapBx};
			var addressRepBestLine1 = streetIds[mapIdS] + " " + mapHs;
			if (mapBx !== "empty") {addressRepBestLine1 = addressRepBestLine1 + " , " + mapBx};
			issue.addressRepBestLine1 = addressRepBestLine1;
			if (lan === 'nl') {issue.addressRepBestLine2 = mapP + " " + issue.Mnl2};
			if (lan === 'fr') {issue.addressRepBestLine2 = mapP + " " + issue.Mfr2};
			if (lan === 'de') {issue.addressRepBestLine2 = mapP + " " + issue.Mde2};

			if ((issue.Pbpost !== "" &&  issue.P2 !== issue.Pbpost) || issue.P2 !== mapP) {
				//'mun.decision: ok – manually mapped, postcode correction to be done in BEST';
				issue.action = '21';
				setWarningPostcode(lblRectifyPostcodeBest);
			};
			//console.log("issue.POri, mapP",issue.POri, mapP);
			if (issue.POri !== mapP) {
				//'mun.decision: ok – manually mapped, postcode correction to be done in SRC';
				issue.action = '22';
				setWarningPostcode(lblRectifyPostcodeSrc);
			};
		} else if (decision === "2") {
			issue.idA = "";
			issue.action = '23';
			//'mun.decision: source address will be corrected';
		} else if (decision === "3") {
			issue.idA = "";
			issue.action = '24';
			//'mun.decision: BEST address will be corrected';
		} else if (decision === "4") {
			issue.idA = "";
			issue.action = '96';
			//'mun.decision: RRN address must be removed from RRN';
		}
		console.log("updating issue");

		try {
			const requestOptions = {
			method: 'PUT',
			headers: { 
				'Authorization': authenticationString,
				'Content-Type': 'application/json' 
			},
			body: JSON.stringify({'issue':issue})
			};

			console.log("requestOptions:", requestOptions);

			const response = await fetch(domainBackend + '/issue', requestOptions);
			const resData = await response.json();
			console.log(resData);
			//'update OK', 'user unknown', 'token invalid', 'token expired - pls login again', 'invalid idM'
			setResultUpdate("  " + resData.msg);

			if (resData.msg === 'Issue was updated earlier today') {
				let alertMsg = concurrencyMsg + resData.colleague + ' - timestamp (UTC): ' + resData.issue.upd;
				alert(alertMsg);
				setIssue(resData.issue);
				let updatedIssues = JSON.parse(JSON.stringify(issues)); //deep copy
				updatedIssues[issueIx] = resData.issue;
				setIssues(updatedIssues);
			};
	
			if (resData.msg === 'update OK') {
				if (decision === "1") {
					let msg = resData.msg + '\n'
					        + issue.addressRepBestLine1 + '\n' 
							+ issue.addressRepBestLine2;
					alert(msg);
				} else {
					alert(resData.msg);
				};

				issue.upd = resData.upd;
				issue.user = email;
				//setIssue(updatedIssue);
				let updatedIssues = JSON.parse(JSON.stringify(issues)); //deep copy
				updatedIssues[issueIx] = issue;
				setIssues(updatedIssues);
			};

			setDecision("0");
			return;
		} catch (err) {
			setResultUpdate("  Update problem - try again");
		};
	}

//----------------------------------------------------------------------
	useEffect(() => {
		setDecision("0"); //uncheck radio button
		setFilter1("0");
		setFilter2("All");
		setMapPs([]);
		setMapWarning({"nl":"", "fr":"", "de":""});
		setWarningPostcode("");
		setDisplayP2("");
		setMapIdS("tbd");
		setMapStreetHouseNumbers([]);
		}       
		, [Nis]    //first param is function, second param is Nis (when changed, this useEffect is called) (also called at mount/unmount now)
	);        //end of useEffect
//----------------------------------------------------------------------
// useEffect called at mount and when filters are changed in SelectUI component
	useEffect(function filterChanged() {
		console.log("useEffect filterChanged", filter1, filter2);
		let f1 = filter1;
		let f2 = filter2;
		//solves case where issues don't contain open issues anymore
		if (cntIssues(f1, f2) === 0) {
			if (f1 === "0") {
				if (cntIssues("1", f2) > 0) {
					f1 = "1";
					setFilter1("1");
				};
			};
		};

		for (let i = 0 ; i < issues.length ; i++){
			if ((f2 === "All" || issues[i].warning === f2) && issues[i].treated === f1 &&
			(issues[i].action !== "02" && issues[i].action !== "03")) {
				setIssueIx(i);
				setIssue(issues[i]);
				break;
			}
		}
	}            //end of function filterChanged
	, [issues, filter1, filter2, cntIssues]    //first param is function, if second param changes it triggers useEffect to be called (besides being called at mount/unmount)
	);             //end of useEffect	

//----------------------------------------------------------------------
// useEffect called at mount and every time a new issue is treated on the screen
	useEffect(function gotIssue() {
		if (!isLoggedIn) {
			return;
		};
		if (Object.keys(issue).length === 0) {
			console.log("UseEffect gotIssue: NO ISSUE")
			return;
		};
		console.log("useEffect gotIssue (id, #keys in streetObjIdToIdS, #keys in mapNumbers):", issue._id, Object.keys(streetObjIdToIdS).length, Object.keys(mapNumbers).length); 
		setResultUpdate("");
		var house = "";
		var box = "";
		var hs_bx = "";

		setComment(issue.comment);

		//fill in postcode box: mapPs
		console.log("P - POri - Pbpost - P2 - lstP2 - P3", issue.P, issue.POri, issue.Pbpost, issue.P2, issue.lstP2, issue.P3);
		setMapWarning({"nl":"", "fr":"", "de":""});
		if (issue.Pbpost !== "") {			    //BPOST correction
			setMapPs([issue.Pbpost]);
		} else if (issue.P === issue.P2) {		//this covers the majority of cases 
			setMapPs([issue.P]);
		} else {
			const myString = issue.lstP2;
			let myString2 = myString.slice(1, -1); 		//remove [ and ] of the string
			myString2 = myString2.replaceAll("'", ""); 	//remove single quotes
			const lst = myString2.split(',');	
			lst.push(issue.P);
			if ((issue.P2 !== "") && (! lst.includes(issue.P2))) {
				lst.push(issue.P2);
			};
			setMapPs(lst);
			if (lst.length > 1) {
				setMapWarning(lblMapWarning_multi);     //choose postcode as there is more than 1 in the listbox
			};
		};

		//fill display message for P2 in the BEST data part, possibly with BPOST correction message
		if (issue.Pbpost !== "") {
			setDisplayP2(issue.Pbpost + " (BPOST)");
		} else if (issue.lstP2 === '[]') {
			setDisplayP2(issue.P2);
		} else {
			setDisplayP2(issue.P2+issue.lstP2);
		};

		//fill in mapP
		if (issue.P3) {
				setMapP(issue.P3);
		} else {
			setMapP(issue.P);
		};

		// fill in other maps
		setWarningPostcode("");

		let idS = issue.idS;
		if (idS) {
			let lst = idS.split('_');
			const idStreet = lst[0] + '_' + lst[1]; //we drop the version id to search in mapNumbers - why ? there are street version differences e.g. Antwerpen, Entrepotplaats
			//console.log("mapNumbers", mapNumbers);
			if (idStreet in mapNumbers) {					
				//there is a BEST street id and it has numbers
				const hs2 = get_BEST_nrs(issue, mapNumbers).myHs
				let bx2 = get_BEST_nrs(issue, mapNumbers).myBx
				
				//console.log("idStreet", idStreet);
				//console.log("hs2", hs2);
				//console.log("bx2", bx2);
				//console.log("mapNumbers[idStreet]", mapNumbers[idStreet]);
				//console.log("mapNumbers[idStreet][hs2]", mapNumbers[idStreet][hs2]);

				if (hs2 in mapNumbers[idStreet]) {
					if (!mapNumbers[idStreet][hs2].includes(bx2)) {
						bx2 = mapNumbers[idStreet][hs2][0];
					};
					house = hs2;
				} else {									//incident 2024-02-08 Pelt (issues file had hs 3, while numbers only had 3A, 3B and 3C)
					house = mapNumbers[idStreet]["hs"][0];
					bx2 = mapNumbers[idStreet][house][0];
				};


				if (house) { 		//find box in the BEST part
					if (bx2 && bx2 !== 'EMPTY') {
						box = bx2;
					} else {
						box = "empty";
					};
				} else {			//find house and box in the SRC part
					if (mapNumbers[idStreet]["hs"].includes(issue.hs)) {
						house = issue.hs;
						if (mapNumbers[idStreet][house].includes(issue.bx)) {
							box = issue.bx;
						} else {
							box = mapNumbers[idStreet][house][0];
						}
					} 
				};
				hs_bx = house + "_|_" + box;
	
				setMapIdS(idS);
				setMapStreetHouseNumbers(mapNumbers[idStreet]["hs"]);
				if (house) {
					setMapBoxNumbers(mapNumbers[idStreet][house]);
				} else {
					house = mapNumbers[idStreet]["hs"][0];
					setMapBoxNumbers(mapNumbers[idStreet][house]);
					box = mapNumbers[idStreet][house][0];
					hs_bx = house + "_|_" + box;
				};
				setMapHs(house);
				setMapBx(box);
				setMapIdA(mapNumbers[idStreet][hs_bx]);
			} else {
				//there is a BEST street id but it does not have numbers
				setMapIdS(idS);
				setMapStreetHouseNumbers([]);
				setMapBoxNumbers([]);
				setMapHs("");
				setMapBx("");
				setMapIdA("");
			};
		} else {
			//there is no BEST street id, so pick the first street in mapNumbers
			for (let id in mapNumbers) {
				const lst = id.split('_');
				const objId = lst[1];	
				let idS = streetObjIdToIdS[objId];
				console.log("id, streetObjIdToIdS[objId]", id, idS);
				house = mapNumbers[id]["hs"][0];
				box = mapNumbers[id][house][0];
				hs_bx = house + "_|_" + box;
				setMapIdS(idS);
				setMapStreetHouseNumbers(mapNumbers[id]["hs"]);
				setMapBoxNumbers(mapNumbers[id][house]);
				setMapHs(house);
				setMapBx(box);
				setMapIdA(mapNumbers[id][hs_bx]);
				break;
			}
		};

		}            //end of function gotIssue
		, [isLoggedIn, issue, mapNumbers, streetObjIdToIdS, lan]    //first param is function, if second param changes it triggers useEffect to be called (besides being called at mount/unmount)
	  );             //end of useEffect	


//----------------------------------------------------------------------------------------------
return (
	<div className="wrapper">
		<div className="box-stats">
			<Stats 
				language = {lan}
				issues = {issues}
			/>
		</div>

		<div className="box-selectUI">
			<SelectUI 
				language = {lan}
				filter1 = {filter1}
				onFilter1 ={updFilter1}
				filter2 = {filter2}
				onFilter2 ={updFilter2}
			/>
		</div>

		<div className="box-srcdata">
			<SrcDataUI 
				SRC = {SRC}
				language = {lan}
				_id = {issue._id}
				idS_SRC = {issue.idS_SRC}
				P = {(issue.P === issue.POri) ? issue.P : issue.POri + " (RRN) => " + issue.P + " (BPOST)"}
				S = {issue.S}
				Snl = {issue.Snl}
				Sfr = {issue.Sfr}
				Sde = {issue.Sde}
				hs = {issue.hsOri}
				bx = {issue.bxOri}
			/>
		</div>

		<div className="box-bestdata">
			<BestDataUI 
				language = {lan}
				idA = {issue.idA}
				R = {issue.R}
				idS = {issue.idS}
				P2 = {displayP2}
				Snl2 = {issue.Snl2}
				Sfr2 = {issue.Sfr2}
				Sde2 = {issue.Sde2}
				hs2 = {get_BEST_nrs(issue, mapNumbers).myHs}
				bx2 = {get_BEST_nrs(issue, mapNumbers).myBx}
				warning = {issue.warning}
				action = {issue.action}
				isTreated = {issue.treated}
				upd = {issue.upd}
				user = {issue.user}
			/>
			<br/>
		</div>

		<div className="box-previous">
			<PrevIssue
				language={lan}
				onClick={handlePrevious}
				ix = {issueIx}
				step = {-step}
			/>
		</div>
		
		<div className="box-addressRepresentationSrc">
			<AddressRepresentation 
				idS = {issue.idS}
				Snl = {issue.Snl}
				Sfr = {issue.Sfr}
				Sde = {issue.Sde}
				hs = {issue.hsOri}
				bx = {issue.bxOri}
				P = {issue.P}
				P2 = {(issue.lstP2 === "[]") ? issue.P2 : issue.P2 + issue.lstP2}
				typ = {'SRC'}
				streetnameDic = {streetnameDic}
			/>
		</div>

		<div className="box-addressRepresentationBest">
			<AddressRepresentation 
				idS = {issue.idS}
				Snl = {issue.Snl2}
				Sfr = {issue.Sfr2}
				Sde = {issue.Sde2}
				hs = {get_BEST_nrs(issue, mapNumbers).myHs}
				bx = {get_BEST_nrs(issue, mapNumbers).myBx}
				P = {issue.P}
				P2 = {(issue.lstP2 === "[]") ? issue.P2 : issue.P2 + issue.lstP2}
				typ = {'BEST'}
				streetnameDic = {streetnameDic}
			/>
		</div>

		<div className="box-next">
			<NextIssue
				language={lan}
				onClick={handleNext}
				ix = {issueIx}
				step = {step}
				onStepChange={changeStep}
			/>
		</div>

		<div className="box-mappingDecision">
			<h1 className="heading">{hdrDecision}</h1>
		</div>

		<div className="box-mapping">
			<Mapping 
				mapPs = {mapPs}
				mapP = {mapP}
				onMapP = {handleMapP}
				mapWarning = {mapWarning[lan]}

				mapStreetnamesNL = {mapStreetnamesNL}
				mapStreetnamesFR = {mapStreetnamesFR}
				mapStreetnamesDE = {mapStreetnamesDE}
				mapIdS = {mapIdS}
				onMapIdS = {handleMapIdS}
				// mapS = {mapS}

				mapStreetHouseNumbers = {mapStreetHouseNumbers}
				mapHs = {mapHs}
				onMapHs = {handleMapHs}

				mapBoxNumbers = {mapBoxNumbers}
				mapBx = {mapBx}
				mapIdA = {mapIdA}
				onMapBx = {handleMapBx}
			/>
		</div>

		<div className="box-decision">
			<Decision 
				language = {lan}
				decision = {decision}
				onDecision = {handleDecision}
				warningPostcode = {warningPostcode}
				comment = {comment}
				onComment = {handleComment}
			/>
			<br/>
		    <button onClick={updateIssue} disabled={blockUpdates}>{lblUpdate}</button> 
			{resultUpdate}
    		<br/><br/>
		</div>

		<div className="box-footer">
			<Footer 
				language = {lan}
			/>
		</div>
	</div>
  );
}
export default Issues;