import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";

//import LoadMunicipalityData from './LoadMunicipalityData';
import Municipality from "../best/Municipality";
import classes from "./Municipalities.module.css";

const lblMunicipality = {'nl':'Gemeente : ', 'fr':'Commune : ', 'de': 'Gemeinde : '};



const Municipalities = () => {
    console.log("RENDER Municipalities.js");

    const authCtx = useContext(AuthContext);
    const lan = authCtx.language;
    const domainBackend = authCtx.domainBackend;
    const isAdmin = authCtx.isAdmin;
    const email = authCtx.email;
    const token = authCtx.token;
    const selectMunicipality = authCtx.selectMunicipality;
    const municipalities = authCtx.municipalities;
    const setMunicipalities = authCtx.setMunicipalities;
    const NIS = authCtx.NIS;
	const setPage = authCtx.setPage;
	setPage(""); //cfr Header.js

    const fetchIssues = authCtx.fetchIssues;
    const fetchStreetnames = authCtx.fetchStreetnames;
    const fetchNumbers = authCtx.fetchNumbers;
    const createAuthenticationString = authCtx.createAuthenticationString;

//-------------------------------------------------------------------------------------------
    //const [fetchMunicipalityData, setFetchMunicipalityData] = useState(false);
    const [warning, setWarning] = useState({});
    const [newNIS, setnewNIS] = useState(false);
//-------------------------------------------------------------------------------------------
    const history = useHistory();

//--------------------------------------------------------------------------------------------------
    if (Object.keys(municipalities).length === 0) {
        fetchMunicipalities();
    }

    const lst = [];
    for (const [key, value] of Object.entries(municipalities)) {
        if (lan === "nl") {
            lst.push([value['Mnl'], key]);
        };
        if (lan === "fr") {
            lst.push([value['Mfr'], key]);
        };
        if (lan === "de") {
            lst.push([value['Mde'], key]);
        };
    };
    lst.sort();
    lst.unshift(["tbd", null]);

    const selectMunicipalityHandler = (event) => {
        setnewNIS(true);
        const Nis = event.target.value;
        console.log("NIS", Nis);
        const idM = municipalities[Nis].idM;
        const Mnl = municipalities[Nis].Mnl;
        const Mfr = municipalities[Nis].Mfr;
        const Mde = municipalities[Nis].Mde;
        if (isAdmin) {
            selectMunicipality(Nis, idM, Mnl, Mfr, Mde);
            //console.log(Nis, idM, Mnl, Mfr, Mde);
            fetchIssues(Nis, email, token);
            fetchStreetnames(idM, email, token);
            fetchNumbers(idM, email, token);
            //history.replace('/issues');
        } else {
            authCtx.logout();
            history.replace('/');
        };
    };

//-------------------------------------------------------------------------------------------
async function fetchMunicipalities() {
    try {
        let authenticationString = createAuthenticationString(email, token);
        const requestOptions = {
            method: 'GET',
            headers: { 
                'Authorization': authenticationString, 
                'Content-Type': 'application/json' 
            }
       };
    
       const response = await fetch(domainBackend + '/municipalities', requestOptions);
       const resData = await response.json();
       if (!response.ok) {
         throw new Error('Fetching the municipalities failed.');
       }

       setMunicipalities(resData);

    } catch (err) {
        setWarning({
            "nl": "Het ophalen van gemeenten is mislukt - de server reageerde met een fout.",
            "fr": "Échec de la récupération des municipalités - le serveur a répondu par une erreur.",
            "de": "Das Abrufen der Gemeinden ist fehlgeschlagen - der Server hat mit einem Fehler geantwortet."
            });

    };
};

//----------------------------------------------------------------------
    useEffect(() => {
        if (!isAdmin) {
            return
        };
        if (NIS  && NIS !== 'BOSA' && newNIS) {
            setnewNIS(false);
            history.replace('/issues');
        }
    }       
        , [NIS, isAdmin, history, newNIS]    //first param is function, second param is dependency (when changed, this useEffect is called) (also called at mount/unmount now)
    );        //end of useEffect
//----------------------------------------------------------------------
    return (
        <div className={classes.municipalityList}>
            { warning && <p>{warning[lan]}</p>}
            <label htmlFor="lstMunicipalities">{lblMunicipality[lan]}</label>
            <select name="municipalities" id="lstMunicipalities" onChange={selectMunicipalityHandler}>
                {lst.map((item) => {
                    return (
                        <Municipality
                            name= {item[0]}
                            Nis={item[1]} 
                        />
                        )
                    })
                }
            </select> 
        </div>
    )
};

export default Municipalities;