import React, { useRef, useContext, useState } from "react";
//import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import classes from './ResetPasswordForm.module.css';
const lblResetPassword_multi = {"nl":"Reset paswoord", "fr": "Reset mot de passe", "de": "Reset Passwort"};
const msgResetOK_multi = {"nl":"Reset paswoord OK" , "fr": "Reset mot de passe OK", "de": "Reset Passwort OK"};
const lblInstruction_multi = {"nl":"email adres dat moet worden gereset: " , "fr": "adresse e-mail à réinitialiser: ", "de": "E-Mail-Adresse zum Zurücksetzen: "};

const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}

const ResetPasswordForm = () => {
  const authCtx = useContext(AuthContext);
  const email = authCtx.email;
  const token = authCtx.token;
  const lan = authCtx.language;
  const domainBackend = authCtx.domainBackend;
	const setPage = authCtx.setPage;
	setPage(""); //cfr Header.js

  const userToBeReset = useRef();

  const[warning, setWarning] = useState({});

  //const history = useHistory();

  const lblResetPassword = lblResetPassword_multi[lan];
  const msgResetOK = msgResetOK_multi[lan];
  const lblInstruction = lblInstruction_multi[lan];

  async function handleResetPassword(event) {
    event.preventDefault();
    if (! userToBeReset.current.value) {
      setWarning({
        "nl": "Vul in userToBeReset",
        "fr": "Remplissez userToBeReset",
        "de": "userToBeReset eingeben",
      });
      return;
    }
     try {
      let authenticationString = createAuthenticationString(email, token);
      const requestOptions = {
          method: 'POST',
          headers: { 
              'Authorization': authenticationString, 
              'Content-Type': 'application/json' 
          },
        body: JSON.stringify({"email": email, "token": token, "emailToBeReset": userToBeReset.current.value})
      };
      const response = await fetch(domainBackend + '/resetPassword', requestOptions);
      const resData = await response.json();
      console.log(resData);
      const msg = resData.msg;

      if (msg ==='password is reset to initial value') { 
        alert(userToBeReset.current.value + ': ' + msgResetOK);
        //history.replace('/issues');
        userToBeReset.current.value = '';
        return;
      };
      if (msg ==='user does not exist') { 
        setWarning({
          "nl": "Gebruiker bestaat niet",
          "fr": "L'utilisateur n'existe pas",
          "de": "Benutzer existiert nicht",       
        });
        return;
      };
      if (msg ==='user to be reset does not exist') { 
        setWarning({
          "nl": "Gebruiker bestaat niet",
          "fr": "L'utilisateur n'existe pas",
          "de": "Benutzer existiert nicht",       
        });
        return;
      };

      if (msg ==='admin rights are required') { 
        setWarning({
          "nl": "Onvoldoende rechten",
          "fr": "Droits insuffisants",
          "de": "Unzureichende Rechte",       
        });
        return;
      };

      if (msg ==='token expired , pls login again') { 
        setWarning({
          "nl": "Token expired",
          "fr": "Token expiré",
          "de": "Token abgelaufen",       
        });
        return;
      };
      if (msg ==='token invalid') { 
        setWarning({
          "nl": "Token ongeldig",
          "fr": "Token invalide",
          "de": "Token ungültig",       
        });
        return;
      };

      if (msg ==='technical issue , pls retry' ) {
        setWarning({
          "nl": "Probleem in ResetPassword",
          "fr": "Problème dans ResetPassword",
          "de": "Problem in ResetPassword",       
        });
        return;
      };

    } catch (err) {
      setWarning({
        "nl": "Probleem in handleResetPassword()",
        "fr": "Problème dans handleResetPassword()",
        "de": "Problem in handleResetPassword()",       
      });
    }

  }
  return (
    <section className={classes.auth}>
      <form onSubmit={handleResetPassword}>
        <div className={classes.control}>
          <label for="userToBeReset">{lblInstruction}</label>
          <input type="text" name="userToBeReset" id="userToBeReset"  ref={userToBeReset}/>
         </div>
        <div className={classes.actions}>
          <button type='submit'>{lblResetPassword}</button>
        </div>
      </form>
      <p>{warning[lan]}</p>
    </section>
  );
};

export default ResetPasswordForm;