import { Fragment } from "react";
import Header from '../components/UI/Header';
import ResetPasswordForm from '../components/Auth/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <Fragment>
     <Header/>
     <ResetPasswordForm />
   </Fragment>);   
};

export default ResetPasswordPage;
