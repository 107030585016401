import React from "react";
import "./SrcDataUI.css";

const lblHdr_multi = {"nl":"Bron adres","fr":"Adresse source","de":"Quelladresse"};
const lblId_multi = {"nl":"Sleutel : ","fr":"Clef : ","de":"Schlüssel : "};
const lblIdS_SRC_multi = {"nl":"id Straat : ","fr":"id Rue : ","de":"id Straße : "};
const lblP_multi = {"nl":"Postcode : ","fr":"Code postal : ","de":"Postleitzahl : "};
const lblS_multi = {"nl":"Straatnaam : ","fr":"nom de la Rue : ","de":"Straßenname : "};
const lblHs_multi = {"nl":"Nummer : ","fr":"Numéro : ","de":"Nummer : "};
const lblBx_multi = {"nl":"Bus : ","fr":"Boîte : ","de":"Bus : "};


function SrcDataUI(props) {
  const lan = props.language;
  const lblHdr = lblHdr_multi[lan];
  const lblId = lblId_multi[lan];
  const lblIdS_SRC = lblIdS_SRC_multi[lan];
  const lblP = lblP_multi[lan];
  const lblS = lblS_multi[lan];
  const lblHs = lblHs_multi[lan];
  const lblBx = lblBx_multi[lan];

  let S = props.S;
  if (lan === 'nl') {
      if (props.Snl) {S = props.Snl}
      else {
          if (props.Sfr) {S = props.Sfr}
          else {S = props.Sde}
      }
  };
  if (lan === 'fr') {
    if (props.Sfr) {S = props.Sfr}
    else {
        if (props.Snl) {S = props.Snl}
        else {S = props.Sde}
    }
  };
  if (lan === 'de') {
    if (props.Sde) {S = props.Sde}
    else {
        if (props.Snl) {S = props.Snl}
        else {S = props.Sfr}
    }
  };
  //console.log("PROPS.P", props.P);

  return (
  <div>
	  <h1 className="heading">{lblHdr+" ("+props.SRC+")"}</h1>

    <div>
        <table> 
         <tbody> 
          <tr>
              <td><label htmlFor="">{lblId}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props._id}/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblP}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.P}  className="postcode"/></td>
          </tr>

          <tr>
              <td><label htmlFor="">{lblIdS_SRC}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.idS_SRC}/></td>
          </tr> 


          <tr>
              <td><label htmlFor="">{lblS}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={S} className="box-input"/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblHs}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.hs}/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblBx}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.bx}/></td>
          </tr>
         </tbody>
        </table>
    </div> 

  </div>
  );
}
export default SrcDataUI;