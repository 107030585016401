import { Fragment } from "react";
import Header from '../components/UI/Header';
import Municipalities from '../components/Auth/Municipalities';

const MunicipalitiesPage = () => {
  return (
    <Fragment>
     <Header/>
     <Municipalities />
   </Fragment>);  
};

export default MunicipalitiesPage;
