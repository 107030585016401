import React, { useContext } from "react";
import AuthContext from "../../store/auth-context";

import "./AddressRepresentation.css";


function AddressRepresentation(props) {
    const authCtx = useContext(AuthContext);
    const lan = authCtx.language;
    const Mnl = authCtx.Mnl;
    const Mfr = authCtx.Mfr;
    const Mde = authCtx.Mde;

    var S = props.S;
    const Snl = props.Snl;
    const Sfr = props.Sfr;
    const Sde = props.Sde;
    const hs = props.hs;
    const bx = props.bx;
    const P = props.P;
    const P2 = props.P2;
    const typ = props.typ; //SRC or BEST
    const idS = props.idS;
    //const streetObjIdToIdS = props.streetObjIdToIdS;
    const streetnameDic = props.streetnameDic;
  
    let M = Mnl;
    if (lan === 'fr') {M = Mfr;};
    if (lan === 'de') {M = Mde;};


    //-----------------------------
    if (typ === 'SRC') {
      if (lan === 'nl') {
          if (Snl) {S = Snl}
          else {
              if (Sfr) {S = Sfr}
              else {S = Sde}
          }
      };
      if (lan === 'fr') {
        if (Sfr) {S = Sfr}
        else {
            if (Snl) {S = Snl}
            else {S = Sde}
        }
      };
      if (lan === 'de') {
        if (Sde) {S = Sde}
        else {
            if (Snl) {S = Snl}
            else {S = Sfr}
        }
      };  
    };
    //-----------------------------
    if (typ === "BEST") {
      S = '';
      // let idS = "";
      // if (objIdS) {
      //     if (streetObjIdToIdS.hasOwnProperty(objIdS)) {
      //         //console.log("streetObjIdToIdS.hasOwnProperty")
      //         idS = streetObjIdToIdS[objIdS]; //full streetname id
      //     };
      // };
      //console.log("BestData: objIdS, idS", objIdS, idS);
    
      var S2nl = "";
      var S2fr = "";
      var S2de = "";
    
      if (idS in streetnameDic) {
        const names = streetnameDic[idS];
        if ('nl' in names) {S2nl = names['nl'];};
        if ('fr' in names) {S2fr = names['fr'];};
        if ('de' in names) {S2de = names['de'];};
      };
      //console.log("S2nl, S2fr, S2de", S2nl, S2fr, S2de); 
    
      if (lan === 'nl') {
        if (S2nl) {S = S2nl;}
        else {
            if (S2de)  {S=S2de;}
            else {
                if (S2fr) {S=S2fr;}
            }
        }
    
      };  
      if (lan === 'fr') {
        if (S2fr) {S = S2fr;}
        else {
            if (S2nl)  {S=S2nl;}
            else {
                if (S2de) {S=S2de;}
            }
        }
      };
    
      if (lan === 'de') {
        if (S2de) {S = S2de;}
        else {
            if (S2nl)  {S=S2nl;}
            else {
                if (S2fr) {S=S2fr;}
            }
        }
      };
    };
    //-----------------------------------------------
    var addressRepLine1 = S + "  " + hs;
    if (bx) {
      addressRepLine1 = addressRepLine1 + " , " + bx;
    };

    var addressRepLine2 = "";
    if (typ === "SRC") {
      addressRepLine2 = P + " " + M
    };
    if (typ === "BEST") {
      addressRepLine2 = P2 + " " + M;
    };

    
  return (
  <div>
    <table>
      <tbody>
        <tr>
            <td>{addressRepLine1}</td>
        </tr> 
        <tr>
            <td>{addressRepLine2}</td>
        </tr> 
        </tbody>
    </table>
  </div>
  );
}
export default AddressRepresentation;