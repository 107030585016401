import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import AuthContext from "../../store/auth-context";

import classes from "./MainNavigation.module.css";

var dicLanguage = {"nl": "Nederlands", "fr": "Français", "de": "Deutsch"};
const languages = [];
var k, v;
for ([k,v] of Object.entries(dicLanguage)){
  languages.push(<option key={k} value={k}>{v}</option>)
};
const changePassword_multi = {"nl": "Paswoord wijzigen", "fr": "Changer le mot de passe", "de": "Passwort ändern"};
const resetPassword_multi = {"nl": "Paswoord resetten", "fr": "Reset mot de passe", "de": "Reset Passwort"};

const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}
//-------------------------------------------------------------------------------------------
const MainNavigation = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const isAdmin = authCtx.isAdmin;
  const lan = authCtx.language;
  const email = authCtx.email;
  const token = authCtx.token;
  const domainBackend = authCtx.domainBackend;

  const history = useHistory();

  const changePassword = changePassword_multi[lan];
  const resetPassword = resetPassword_multi[lan];

  const logoutHandler = async () => {
    authCtx.logout();
    history.replace('/');
    try {
      let authenticationString = createAuthenticationString(email, token);
      const requestOptions = {
          method: 'POST',
          headers: { 
              'Authorization': authenticationString, 
              'Content-Type': 'application/json' 
          }
      };
      const response = await fetch(domainBackend + '/logout', requestOptions);
      const resData = await response.json();
      console.log(resData);

      const msg = resData.msg;
      console.log("msg", msg);

      if (msg ==='user does not exist') { 
				throw new Error('Logout', msg);
      };

      if (msg ==='token invalid' ) {
				throw new Error('Logout', msg);
      };

      return;

    } catch (err) {
          alert("issue at logout");
      };
  }; 

  const languageHandler = (event) => {
    const lan = event.target.value;
    authCtx.setLanguage(lan);
  }; 


  return (
    <header className={classes.header}>
      <div className={classes.logo}>Mapping RRN - BEST</div>
      <nav className={classes.nav}>
        <ul>
          {!isLoggedIn && (
            <li>
              <Link to="/login">Login</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/changePassword">{changePassword}</Link>
            </li>
          )}
          {isAdmin && (
            <li>
              <Link to="/resetPassword">{resetPassword}</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/download">Download</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/issues">Issues</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/trickies">Tricky mappings</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <Link to="/doubles">Double addresses</Link>
            </li>
          )}
          {isAdmin && (
            <li>
              <Link to="/municipalities">Municipalities</Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Logout</button>
            </li>
          )}
        </ul>
      </nav>
      <select 
        name="language" id="lstbxLan" onChange={languageHandler} >
        {languages}
      </select>                

    </header>
  );
};

export default MainNavigation;
