import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthContext from "./store/auth-context";

import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage';
import IssuesPage from './pages/IssuesPage';
import TrickiesPage from './pages/TrickiesPage';
import DoublesPage from './pages/DoublesPage';
import LoginPage from './pages/LoginPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import DownloadPage from './pages/DownloadPage';
import MunicipalitiesPage from './pages/MunicipalitiesPage';


function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const isFirstTime = authCtx.isFirstTime;
  const isAdmin = authCtx.isAdmin;

  console.log("App: isLoggedIn, isAdmin", isLoggedIn, isAdmin);
  return (
    <Layout>
      <Switch>
        <Route path='/' exact>
          <HomePage />
        </Route>
        <Route path='/login'>
          <LoginPage />
        </Route>
        <Route path='/issues'>
          {isLoggedIn && <IssuesPage />}
          {!isLoggedIn && <Redirect to='/' />}
        </Route>
        <Route path='/trickies'>
          {isLoggedIn && <TrickiesPage />}
          {!isLoggedIn && <Redirect to='/' />}
        </Route>
        <Route path='/doubles'>
          {isLoggedIn && <DoublesPage />}
          {!isLoggedIn && <Redirect to='/' />}
        </Route>
        <Route path='/changePassword'>
          {(isLoggedIn || isFirstTime) && <ChangePasswordPage />}
          {!(isLoggedIn || isFirstTime)  && <Redirect to='/' />}
        </Route>
        <Route path='/resetPassword'>
          {isAdmin && <ResetPasswordPage />}
          {!isAdmin && <Redirect to='/' />}
        </Route>
        <Route path='/download'>
          {isLoggedIn && <DownloadPage />}
          {!isLoggedIn && <Redirect to='/' />}
        </Route>
        <Route path='/municipalities'>
          {isAdmin && <MunicipalitiesPage />}
          {!isAdmin && <Redirect to='/' />}
        </Route>
        <Route path='*'>
		      <Redirect to='/' />
		    </Route>
      </Switch>
    </Layout>
  );
}

export default App;
