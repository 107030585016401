import { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import classes from './StartingPageContent.module.css';

const lblHeader_multi = {'nl':'Hartelijk welkom !', 'fr':'Bienvenu(e) !', 'de':'Herzlich willkommen !'};
const txtGoalApplication_multi = {
  'nl':'De bedoeling van deze applicatie is dat de gemeente de laatste mappingproblemen van Rijksregister-adressen naar BEST-adressen oplost.',
  'fr':'Le but de cette application est que la municipalité résout les derniers problèmes de mapping des adresses du Registre National vers les adresses de BEST.',
  'de':'Der Zweck dieser Anwendung ist dass die Gemeinde die letzten Mappingprobleme von den Nationalregisteradressen zu den BEST-Adressen löst.'
};

const cookieConsent_multi = {
  'nl': 'Deze website maakt gebruik van cookies. Deze cookies zijn nodig voor de werking van de website en worden verwijderd wanneer u klaar bent met browsen. Deze cookies en de informatie die ze bevatten, mogen niet worden gebruikt voor andere doeleinden dan hierboven beschreven. Door verder te bladeren op deze website, verklaart u het gebruik van cookies te accepteren.',
  'fr': "Ce site web utilise des cookies. Ces cookies sont nécessaires au fonctionnement du site et seront supprimés à la fin de votre navigation. Ces cookies et les informations qu'ils véhiculent ne seront pas utilisés à d'autres fins que celles décrites ci-dessus. En poursuivant votre navigation sur ce site, vous déclarez accepter l'utilisation de cookies.",
  'de':'Diese Website verwendet Cookies. Diese Cookies sind für den Betrieb der Website erforderlich und werden gelöscht, wenn Sie das Surfen beenden. Diese Cookies und die darin enthaltenen Informationen dürfen nicht für andere als die oben beschriebenen Zwecke verwendet werden. Durch das weitere Surfen auf dieser Website erklären Sie sich mit der Verwendung von Cookies einverstanden.'
}

const weekend_multi = {
  "nl": "WEEKEND: Tijdens het weekend worden de updates geblokkeerd, gelet op het mogelijk overschrijven van deze updates door de weekend jobs.", 
  "fr": "WEEK-END : Pendant le week-end, les mises à jour sont bloquées, en raison de l'écrasement possible de ces mises à jour par les travaux du week-end." ,
  "de": "WEEKEND: Während des Wochenendes werden die Updates blockiert, da diese Updates möglicherweise durch die Wochenendjobs überschrieben werden." 
};

const INC001_multi = {
  "nl": "INCIDENT INC001: Er was afgelopen weekend een probleem met het opleveren van de resultaten van de Municipality Tool.", 
  "fr": "INCIDENT INC001 : Il y a eu un problème avec la livraison des résultats du Municipality Tool le week-end dernier." ,
  "de": "VORFALL INC001: Letztes Wochenende gab es ein Problem mit der Übermittlung der Ergebnisse des Municipality Tools." 
};
const INC002_multi = {
  "nl": "INCIDENT INC002: Er was afgelopen weekend een probleem met de Mapping Tool.", 
  "fr": "INCIDENT INC002: Il y a eu un problème dans le Mapping Tool le week-end dernier.", 
  "de": "VORFALL INC002: Letztes Wochenende gab es ein Problem im Mapping Tool. "
};

const INC003_multi = {
  "nl": "INCIDENT INC003: Er was afgelopen weekend een probleem bij het laden van nieuwe mapping issues.", 
  "fr": "INCIDENT INC003 : Il y a eu un problème lors du chargement des nouveaux mapping issues le week-end dernier." , 
  "de": "VORFALL INC003: Letztes Wochenende gab es ein Problem beim Laden neuer Mapping Issues." 
};

const INC999_multi = {
  "nl": "INCIDENT INC999: Er was afgelopen weekend een incident.", 
  "fr": "INCIDENT INC999 : Il y a eu un problème le week-end dernier." , 
  "de": "VORFALL INC999: Letztes Wochenende gab es ein Problem." 
};

const INC_consequence_multi = {
    "nl": "GEVOLG: Geen nieuwe gegevens voor de Municipality Tool." ,
    "fr": "CONSEQUENCE : Pas de nouvelles données pour le Municipality Tool." ,
    "de": "FOLGE: Keine neuen Daten für das Municipality Tool." 
};

const INC_next_multi = {
 "nl": "U kunt nog steeds de Municipality Tool raadplegen, maar nieuwe updates worden geblokkeerd totdat het probleem is opgelost. Wanneer het probleem is opgelost, ziet u deze melding niet meer bij het inloggen.",
 "fr": "Vous pouvez toujours consulter le Municipality Tool, mais les nouvelles mises à jour seront bloquées jusqu'à ce que le problème soit résolu. Une fois le problème résolu, vous ne verrez plus ce message lors de la connexion.",
 "de": "Sie können das Municipality Tool weiterhin konsultieren, aber neue Updates werden blockiert, bis das Problem behoben ist. Wenn das Problem behoben ist, wird diese Meldung beim Anmelden nicht mehr angezeigt."
};

const lblHelp_multi = {
  "nl":"HELP",
  "fr":"AIDE",
  "de":"HILFE"
}

const lblUserManual_multi = {
  "nl":"Gebruikershandleiding",
  "fr":"Guide d'utilisateur",
  "de":"Benutzerhandbuch"
}
const txtUserManual1_multi = {
  "nl":"Er is een gebruikershandleiding die je via het Download scherm kan ophalen.",
  "fr":"Il existe un manuel d'utilisation que vous pouvez télécharger à partir de l'écran de téléchargement.",
  "de":"Es gibt ein Benutzerhandbuch, das Sie vom Download-Bildschirm herunterladen können."
}
const txtUserManual2_multi = {
  "nl":"Deze gebruikershandleiding legt uit hoe je de tool kan gebruiken en bevat eveneens een FAQ-lijst die regelmatig zal worden bijgewerkt.",
  "fr":"Ce manuel d'utilisation explique comment utiliser l'outil et contient également une liste de FAQ qui sera mise à jour régulièrement.",
  "de":"Dieses Benutzerhandbuch erläutert die Verwendung des Tools und enthält außerdem eine FAQ-Liste, die regelmäßig aktualisiert wird."
}
const lblVideo_multi = {
  "nl":"Instructie-video's",
  "fr":"Vidéos d'instruction",
  "de":"Anleitungsvideos"
}
const txtVideo0_multi = {
  "nl":"taal wijzigen",
  "fr":"changer de langue",
  "de":"Sprache ändern"
}
const urlVideo0_multi = {
  "nl":"https://youtu.be/JKT--9qmAMw",
  "fr":"https://youtu.be/Hxfe9nbq2qs",
  "de":"https://youtu.be/M6RIrng336M"
}

const txtVideo1_multi = {
  "nl":"1e gebruik",
  "fr":"1ère utilisation",
  "de":"erste Verwendung"
}
const urlVideo1_multi = {
  "nl":"https://youtu.be/Dt-ZVtu6WhA",
  "fr":"https://youtu.be/5grBjI7ehas",
  "de":"https://youtu.be/2jJ7Cxrs-iU"
}

const txtVideo2_multi = {
  "nl":"paswoord wijzigen",
  "fr":"changer de mot de passe",
  "de":"Passwort ändern"
}
const urlVideo2_multi = {
  "nl":"https://youtu.be/ryNAKbqL1sw",
  "fr":"https://youtu.be/jleIi7ym3r8",
  "de":"https://youtu.be/NgUS8SdzGP8"
}

const txtVideo3_multi = {
  "nl":"verkennen van het hoofdscherm",
  "fr":"explorer l'écran principal",
  "de":"erkunden Sie den Hauptbildschirm"
}
const urlVideo3_multi = {
  "nl":"https://youtu.be/2d5_LqVIb3M",
  "fr":"https://youtu.be/IIGKDy19gzo",
  "de":"https://youtu.be/QHuaD7PIBf8"
}

const txtVideo4_multi = {
  "nl":"oplossen van mappingproblemen",
  "fr":"résoudre les problèmes de mapping",
  "de":"lösen von Mapping-Problemen"
}
const urlVideo4_multi = {
  "nl":"https://youtu.be/nfX3lXbtiQA",
  "fr":"https://youtu.be/DuWdHGTxFqU",
  "de":"https://youtu.be/A9ndHQ6sdns"
}

const txtVideo5_multi = {
  "nl":"behandelen van postcodes",
  "fr":"traitement des codes postaux",
  "de":"Umgang mit Postleitzahlen"
}
const urlVideo5_multi = {
  "nl":"https://youtu.be/OPFQrfnMi44",
  "fr":"https://youtu.be/5Zv4qHw_oqw",
  "de":"https://youtu.be/HAt7U3-zzJo"
}

const txtVideo6_multi = {
  "nl":"aanvaarden/verwerpen van tricky mappings",
  "fr":"accepter/rejeter les tricky mappings",
  "de":"tricky Mappings akzeptieren/ablehnen"
}
const urlVideo6_multi = {
  "nl":"https://youtu.be/qwyhQeyJsz8",
  "fr":"https://youtu.be/XWkpKfRib_g",
  "de":"https://youtu.be/AuiBtvDgxDE"
}

const txtVideo7_multi = {
  "nl":"opkuisen van dubbele adressen bij het Rijksregister",
  "fr":"nettoyage des adresses en double au registre national",
  "de":"Bereinigung doppelter Adressen im Nationalregister"
}
const urlVideo7_multi = {
  "nl":"https://youtu.be/vZveAwqL6UY",
  "fr":"https://youtu.be/8rkY-EeNyiw",
  "de":"https://youtu.be/lImVuBwOtOo"
}

const txtVideo8_multi = {
  "nl":"downloaden van de todo's",
  "fr":"télécharger les todo's",
  "de":"herunterladen die Todos"
}
const urlVideo8_multi = {
  "nl":"https://youtu.be/n36pjpf2TSc",
  "fr":"https://youtu.be/2rjGTHuWjrE",
  "de":"https://youtu.be/XtWknpO5z_s"
}

const txtVideo9_multi = {
  "nl":"downloaden van de gebruikershandleiding",
  "fr":"télécharger le manuel d'utilisation",
  "de":"herunterladen das Benutzerhandbuch"
}
const urlVideo9_multi = {
  "nl":"https://youtu.be/tTVfl3xXbfk",
  "fr":"https://youtu.be/5rSvabJVksI",
  "de":"https://youtu.be/5EZYnOsc1lk"
}

const lblHelpdesk_multi = {
  "nl":"Service desk",
  "fr":"Service desk",
  "de":"Service desk"
}

const txtHelpdesk_multi = {
  "nl":"Mocht u dan nog vragen hebben, kan u steeds terecht bij de helpdesks (zie gebruikershandleiding).",
  "fr":"Si vous avez encore des questions, vous pouvez toujours contacter les helpdesks (voir guide de l'utilisateur).",
  "de":"Wenn Sie noch Fragen haben, können Sie sich jederzeit an die Helpdesks wenden (siehe Benutzerhandbuch)."
}

const StartingPageContent = () => {
  const authCtx = useContext(AuthContext);
  const lan = authCtx.language;
  const domainBackend = authCtx.domainBackend;
  const handleIncident = authCtx.handleIncident;
  const[warning, setWarning] = useState("");
 

  const lblHeader = lblHeader_multi[lan];
  const txtGoalApplication = txtGoalApplication_multi[lan];
  const cookieConsent = cookieConsent_multi[lan];

  const lblHelp = lblHelp_multi[lan];

  const lblUserManual = lblUserManual_multi[lan];
  const txtUserManual1 = txtUserManual1_multi[lan];
  const txtUserManual2 = txtUserManual2_multi[lan];

  const lblVideo = lblVideo_multi[lan];
  const txtVideo0 = txtVideo0_multi[lan];
  const urlVideo0 = urlVideo0_multi[lan];
  const txtVideo1 = txtVideo1_multi[lan];
  const urlVideo1 = urlVideo1_multi[lan];
  const txtVideo2 = txtVideo2_multi[lan];
  const urlVideo2 = urlVideo2_multi[lan];
  const txtVideo3 = txtVideo3_multi[lan];
  const urlVideo3 = urlVideo3_multi[lan];
  const txtVideo4 = txtVideo4_multi[lan];
  const urlVideo4 = urlVideo4_multi[lan];
  const txtVideo5 = txtVideo5_multi[lan];
  const urlVideo5 = urlVideo5_multi[lan];
  const txtVideo6 = txtVideo6_multi[lan];
  const urlVideo6 = urlVideo6_multi[lan];
  const txtVideo7 = txtVideo7_multi[lan];
  const urlVideo7 = urlVideo7_multi[lan];
  const txtVideo8 = txtVideo8_multi[lan];
  const urlVideo8 = urlVideo8_multi[lan];
  const txtVideo9 = txtVideo9_multi[lan];
  const urlVideo9 = urlVideo9_multi[lan];
  const lblHelpdesk = lblHelpdesk_multi[lan];
  const txtHelpdesk = txtHelpdesk_multi[lan];

  let INC = "";
  if (warning === "weekend") {INC = weekend_multi[lan]}
  else if (warning === "INC001") {INC = INC001_multi[lan]}
  else if (warning === "INC002") {INC = INC002_multi[lan]}
  else if (warning === "INC003") {INC = INC003_multi[lan]}
  else if (warning === "INC999") {INC = INC999_multi[lan]};
  const INC_consequence = INC_consequence_multi[lan];
  const INC_next = INC_next_multi[lan];
  if (INC) {
    handleIncident(true);
  } else {
    handleIncident(false);
  };
//------------------------------------------------------------------------------------
useEffect(() => {async function checkIncidents(){
  try {
    const requestOptions = {
        method: 'GET',
        headers: { 
         }
    };
    console.log(requestOptions.headers);
    const response = await fetch(domainBackend + '/checkIncidents', requestOptions);
    const resData = await response.json();
    console.log(resData);

    const sts = resData.status;
    if (sts === 'ok') {
      setWarning("");
    } else if (sts === 'nok') {
        setWarning(resData.msg);
    };
    return;
  } catch (err) {
      setWarning({
      "nl": "Probleem bij het checken van incidenten",
      "fr": "Problème pendant le check d'incidents",
      "de": "Vorfälle bei der Problemprüfung",       
      });
    }
  } checkIncidents();
 }  
  , [domainBackend]    //first param is function, second param is dependency (when changed, this useEffect is called) (also called at mount/unmount now)
);        //end of useEffect


  return (
    <div>
      <section className={classes.starting}>
        <h1 >{lblHeader}</h1>
        <p className={classes.paragraph}>{txtGoalApplication}</p>
        <p className={`${classes.paragraph} ${classes.cookieConsent}`}>{cookieConsent}</p>

        {INC && <p className={classes.incident}>{INC}</p>}
        {INC && warning !== "weekend" && <p className={classes.incident}>{INC_consequence}</p>}
        {INC && warning !== "weekend" && <p className={classes.incident}>{INC_next}</p>}
      </section>
      <section>
        <h1 >{lblHelp}</h1>
        <h2 >{lblUserManual}</h2>
        <p className={classes.paragraph}>
          {txtUserManual1}<br/>
          {txtUserManual2}
          </p>
        <h2 >{lblVideo}</h2>
        <p className={classes.paragraph}>
          <a href={urlVideo0} target="_blank" rel="noopener noreferrer">{txtVideo0}</a><br/>
          <a href={urlVideo1} target="_blank" rel="noopener noreferrer">{txtVideo1}</a><br/>
          <a href={urlVideo2} target="_blank" rel="noopener noreferrer">{txtVideo2}</a><br/>
          <a href={urlVideo3} target="_blank" rel="noopener noreferrer">{txtVideo3}</a><br/>
          <a href={urlVideo4} target="_blank" rel="noopener noreferrer">{txtVideo4}</a><br/>
          <a href={urlVideo5} target="_blank" rel="noopener noreferrer">{txtVideo5}</a><br/>
          <a href={urlVideo6} target="_blank" rel="noopener noreferrer">{txtVideo6}</a><br/>
          <a href={urlVideo7} target="_blank" rel="noopener noreferrer">{txtVideo7}</a><br/>
          <a href={urlVideo8} target="_blank" rel="noopener noreferrer">{txtVideo8}</a><br/>
          <a href={urlVideo9} target="_blank" rel="noopener noreferrer">{txtVideo9}</a>
        </p>
        <h2 >{lblHelpdesk}</h2>
        <p className={classes.paragraph}>
          {txtHelpdesk}
        </p>

      </section>
    </div>
  );
};

export default StartingPageContent;
