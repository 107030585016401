import React from "react";
import "./BestDataUI.css";

const lblHdr_multi = {"nl":"BEST adres","fr":"Adresse BEST","de":"BEST Adresse"};
const lblR_multi = {"nl":"Gewest : ","fr":"id Région : ","de":"id Region : "};
const lblIdS_multi = {"nl":"id Straat : ","fr":"id Rue : ","de":"id Straße : "};
const lblP2_multi = {"nl":"Postcode : ","fr":"Code postal : ","de":"Postleitzahl : "};
const lblS2_multi = {"nl":"Straatnaam : ","fr":"nom de la Rue : ","de":"Straßenname : "};
const lblHs2_multi = {"nl":"Nummer : ","fr":"Numéro : ","de":"Nummer : "};
const lblBx2_multi = {"nl":"Bus : ","fr":"Boîte : ","de":"Bus : "};
const lblIdA_multi = {"nl":"id BEST adres : ","fr":"id Adresse BEST : ","de":"id BEST Adresse : "};
const lblWarning_multi = {"nl":"Type probleem : ","fr":"Type de Problème : ","de":"Art des Problems : "};
const lblAction_multi = {"nl":"Actie : ","fr":"Action : ","de":"Aktion : "};
const lblIsTreated_multi = {"nl":"Is behandeld : ","fr":"Est traité : ","de":"Wurde behandelt : "};

const dicProblem = {"??":{"nl":"", "fr":"", "de":""}, //default when warning not yet rendered
                    "B1":{"nl":"B1. Straat is niet relevant","fr":"B1. Rue irrelevante","de":"B1. Straße irrelevant"},
                    "B2":{"nl":"B2. Straat mapping","fr":"B2. Mapping rue","de":"B2. Mapping der Straße"},
                    "B3":{"nl":"B3. Incoherente postcode","fr":"B3. Code postal incohérent","de":"B3. Inkohärente Postleitzahl"},
                    "C1":{"nl":"C1. Straat zonder huisnummers in BEST","fr":"C1. Rue sans numéros de maison dans BEST","de":"C1. Straße ohne Hausnummern in BEST"},
                    "C2":{"nl":"C2. Huisnummers verschillen","fr":"C2. Différents numéros de maison","de":"C2. Unterschiedliche Hausnummern"},
                    "C3":{"nl":"C3. Ambigue straten","fr":"C3. Rues ambiguës","de":"C3. Mehrdeutige Straßen"},
                    "D1":{"nl":"D1. Busnummer ontbreekt in BEST","fr":"D1. Numéro de boîte manquant dans BEST","de":"D1. Boxnummer fehlt in BEST"},
                    "D2":{"nl":"D2. Busnummers verschillen","fr":"D2. Différents numéros de boîte","de":"D2. Unterschiedliche Boxnummern"},
                    "D3":{"nl":"D3. Busnummer ontbreekt in de bron","fr":"D3. Le numéro de boîte est manquant dans la source","de":"D3. Die Boxnummer fehlt in der Quelle"},
                    "Z2":{"nl":"Z2. Tricky adres","fr":"Z2. Tricky adresse rejetée","de":"Z2. Tricky Adresse"},
                    "Z3":{"nl":"Z3. Dubbel adres","fr":"Z3. Double adresse","de":"Z3. Doppelte Adresse"}
};

const dicAction = { "??":{"nl":"", "fr":"", "de":""}, //default when warning not yet rendered
                    "01":{"nl":"(01) OK - geen actie","fr":"(01) OK - aucune action","de":"(01) OK - keine Aktion"},
                    "02":{"nl":"(02) Te valideren","fr":"(02) A valider","de":"(02) Zu bestätigen"},
                    "03":{"nl":"(03) Dubbel adres","fr":"(03) Adresse en double","de":"(03) Doppelte Adresse"},
                    "04":{"nl":"(04) Te behouden dubbel adres","fr":"(04) Double adresse à conserver","de":"(04) Doppelte Adresse behalten"},
                    "05":{"nl":"(05) Te verwerpen dubbel adres","fr":"(05) Double adresse à rejeter","de":"(05) Doppelte Adresse zum Ablehnen"},
                    "11":{"nl":"(11) OK - gegevens kunnen worden genegeerd","fr":"(11) OK - les données peuvent être ignorées","de":"(11) OK - Daten können ignoriert werden"},
                    // "12":{"nl":"OK - geautomatiseerde toevoeging van ontbrekend busnummer in BEST","fr":"OK - ajout automatique du n° de boîte manquant dans BEST","de":"OK - automatisierte Hinzufügung der fehlenden Box Nummer in BEST"},
                    "12":{"nl":"(12) Te bepalen","fr":"(12) A déterminer","de":"(12) Zu definieren"},
                    "18":{"nl":"(18) Te bepalen - ","fr":"(18) A déterminer","de":"(18) Zu definieren"},
                    "19":{"nl":"(19) Te bepalen","fr":"(19) A déterminer","de":"(19) Zu definieren"},
                    "20":{"nl":"(20) De mapping voorgesteld door de gemeente is correct","fr":"(20) Le mapping proposé par la commune est correct","de":"(20) Die von der Gemeinde vorgeschlagene Mapping ist korrekt"},
                    "21":{"nl":"(21) De mapping voorgesteld door de gemeente is correct, BEST postcode correctie moet worden gedaan","fr":"(21) Le mapping proposé par la commune est correct, correction du code postal dans BEST doit être effectuée","de":"(21) Die von der Gemeinde vorgeschlagene Mapping ist korrekt, BEST Postleitzahlkorrektur muss durchgeführt werden"},
                    "22":{"nl":"(22) De mapping voorgesteld door de gemeente is correct, postcode correctie moet worden gedaan in de bron","fr":"(22) Le mapping proposé par la commune est correct, correction du code postal dans la source doit être effectuée","de":"(22) Die von der Gemeinde vorgeschlagene Mapping ist korrekt, Postleitzahlkorrektur in der Quelle muss durchgeführt werden"},
                    "23":{"nl":"(23) Het bron adres moet worden verbeterd in de bron","fr":"(23) L'adresse source doit être corrigée dans la source","de":"(23) Die Quelladresse muss in der Quelle verbessert werden"},
                    "24":{"nl":"(24) Het bron adres ontbreekt in BEST en moet worden toegevoegd in BEST","fr":"(24) L'adresse source est absente en BEST et doit être ajoutée dans BEST","de":"(24) Die Quelladresse fehlt in BEST und muss in BEST hinzugefügt werden"},
                    "96":{"nl":"(96) Het bron  adres moet worden verwijderd uit de bron.","fr":"(96) L'adresse source doit être supprimée dans la source.","de":"(96) Die Quelladresse muss in der Quelle entfernt werden."},

};

//------------------------------------------------------------------------------
function BestDataUI(props) {
  const lan = props.language;

  const lblHdr = lblHdr_multi[lan];
  const lblR = lblR_multi[lan];
  const lblIdS = lblIdS_multi[lan];
  const lblP2 = lblP2_multi[lan];
  const lblS2 = lblS2_multi[lan];
  const lblHs2 = lblHs2_multi[lan];
  const lblBx2 = lblBx2_multi[lan];
  const lblIdA = lblIdA_multi[lan];
  const lblWarning = lblWarning_multi[lan];
  const lblAction = lblAction_multi[lan];
  const lblIsTreated = lblIsTreated_multi[lan];
  
  let S2 = '';
  if (lan === 'nl') {
    if (props.Snl2) {S2 = props.Snl2;}
    else {
        if (props.Sde2)  {S2=props.Sde2;}
        else {
            if (props.Sfr2) {S2=props.Sfr2;}
        }
    }
  };  
  if (lan === 'fr') {
    if (props.Sfr2) {S2 = props.Sfr2;}
    else {
        if (props.Snl2)  {S2=props.Snl2;}
        else {
            if (props.Sde2) {S2=props.Sde2;}
        }
    }
  };

  if (lan === 'de') {
    if (props.Sde2) {S2 = props.Sde2;}
    else {
        if (props.Snl2)  {S2=props.Snl2;}
        else {
            if (props.Sfr2) {S2=props.Sfr2;}
        }
    }
  };
  //console.log("S2", S2); 

  return (
  <div className="bestdata">
	  <h1 className="heading">{lblHdr}</h1>
    <div>
        <table>
         <tbody>
          <tr>
              <td><label htmlFor="">{lblR}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.R}/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblP2}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.P2} className="postcode"/></td>
          </tr>

          <tr>
              <td><label htmlFor="">{lblIdS}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.idS} className="id"/></td>
          </tr> 


          <tr>
              <td><label htmlFor="">{lblS2}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={S2} className="box-input"/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblHs2}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.hs2}/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblBx2}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={props.bx2}/></td>
          </tr>

          <tr>
              <td><label htmlFor="">{lblWarning}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={dicProblem[props.warning || "??"][props.language]} className="box-input"/></td>
          </tr> 

          <tr>
              <td><label htmlFor="">{lblAction}</label></td>
              <td><input type="text" name="" id="" readOnly={true} value={dicAction[props.action || "??"][props.language]} className="box-input"/></td>
          </tr>
          <tr>
              <td><label htmlFor="">{lblIdA}</label></td>
              <td><input type="text" name="" id="" className="id" readOnly={true} value={props.idA}/></td>
          </tr>
          <tr>
              <td><label htmlFor="">{lblIsTreated}</label></td>
              <td><input type="checkbox" name="" readOnly={true} checked={props.isTreated === '1'}/> {props.upd} - {props.user}</td>
          </tr>
         </tbody>
        </table>
    </div> 
  </div>
  );
}
export default BestDataUI;