//import React, { useState } from "react";
import React from "react";

import "./DoublesForIdA.css";
import DoubleRRN from "./DoubleRRN";

const lblChoose_multi = {"nl":"Kies", "fr":"Choisissez", "de":"Wählen Sie"};
const lblReject_multi = {"nl":"Verwerp", "fr":"Rejetez", "de":"Lehnen Sie ab"};

//----------------------------------------------------------------------------
function DoublesForIdA(props) {
console.log("DoublesForIdA");

const lan = props.lan; 
const idA = props.idA;
const lstDoublesForIdA = props.lstDoublesForIdA;
const updDoublesForIdA = props.updDoublesForIdA;

const lblChoose = lblChoose_multi[lan];
const lblReject = lblReject_multi[lan];

const getLanguageString = (lan, snl, sfr, sde) => {
  if (lan === 'nl') { 
    if (snl) { return snl; }
    else if (sfr) { return sfr; }
    else { return sde; }
  };
  if (lan === 'fr') { 
    if (sfr) { return sfr; }
    else if (snl) { return snl; }
    else { return sde; }
  };
  if (lan === 'de') { 
    if (sde) { return sde; }
    else if (sfr) { return sfr; }
    else { return snl; }
  };
};

const streetnameBEST = getLanguageString(props.lan, props.Snl2, props.Sfr2, props.Sde2);

const lstForIdA_contains = (updStatus) => {
  let result = false;
  for (let i = 0; i < lstDoublesForIdA.length; i++) {
    if (lstDoublesForIdA[i]['treated'] === updStatus) {
      result = true;
    };
  };
  return result;
};

const initializeLstForIdA = (defaultStatus) => {
  for (let i = 0; i < lstDoublesForIdA.length; i++) {
    if (lstDoublesForIdA[i]['treated'] !== "reject mapping") {
      lstDoublesForIdA[i]['treated'] = defaultStatus;
    }
  };
};

const changeRejectMapping = (ix) => {
  console.log("changeRejectMapping", ix);
  const updStatusOld = lstDoublesForIdA[ix]['treated'];

  if (updStatusOld === 'todo' || updStatusOld === 'reject double') {
    lstDoublesForIdA[ix]['treated'] = "reject mapping";
  };

  if (updStatusOld === 'accept double') {
    initializeLstForIdA("todo"); //set to "todo" except if "reject mapping"
    lstDoublesForIdA[ix]['treated'] = "reject mapping";
  };

  if (updStatusOld ==='reject mapping') {
    if (lstForIdA_contains('accept double')) {
      lstDoublesForIdA[ix]['treated'] = "reject double";
    } else {
      lstDoublesForIdA[ix]['treated'] = "todo";
    };
  };

  updDoublesForIdA(idA, lstDoublesForIdA);
};

const changeDouble = (ix) => {
  console.log("changeDouble", ix);
  const updStatusOld = lstDoublesForIdA[ix]['treated'];  
  for (let i = 0; i < lstDoublesForIdA.length; i++) {

    if (updStatusOld === "todo") {
      if (i === ix) {
        lstDoublesForIdA[i]['treated'] = "accept double";
      } else if (lstDoublesForIdA[i]['treated'] !== "reject mapping") {
        lstDoublesForIdA[i]['treated'] = "reject double";
      };
    };

    if (updStatusOld === "accept double" ){
      initializeLstForIdA("todo"); //set to "todo" except if "reject mapping"
    };
    if (updStatusOld === "reject double" ){
      initializeLstForIdA("reject double"); //set to "reject double" except if "reject mapping"
      lstDoublesForIdA[ix]['treated'] = "accept double";
    };
    if (updStatusOld === "reject mapping" ){ //theoretical case since in this case the radio button is disabled
    };
  };
  updDoublesForIdA(idA, lstDoublesForIdA);
};

//----------------------------------------------------------------------------
return (
  <div>
    <section className="gridDoubles">
      <label htmlFor="" className="box-double-idA bold">{props.idA} (BEST)</label>
      <label htmlFor="" className="box-double-postcode bold">{props.P2}</label>
      <label htmlFor="" className="box-double-streetcode bold"></label>
      <label htmlFor="" className="box-double-street bold">{streetnameBEST}</label>
      <label htmlFor="" className="box-double-hs bold">{props.hs2}</label>
      <label htmlFor="" className="box-double-bx bold">{props.bx2}</label>
      <label htmlFor="" className="box-double-radioButton bold">{lblChoose}</label>
      <label htmlFor="" className="box-double-checkBox bold">{lblReject}</label>
      <label htmlFor="" className="box-double-updStatus bold"></label>
    </section>

    {lstDoublesForIdA.map((double, index) => {
      return (
          <DoubleRRN
            key = {index}
            ix = {index}
            idA = {idA}
            keyRR = {double['_id']}
            lan = {lan}
            POri = {double['POri']}
            idS_SRC = {double['idS_SRC']}
            Snl = {double['Snl']}
            Sfr = {double['Sfr']}
            Sde = {double['Sde']}
            hsOri = {double['hsOri']}
            bxOri = {double['bxOri'].replace(/ /g, ".")}
            updStatus = {double['treated']}
            changeRejectMapping = {changeRejectMapping}
            changeDouble = {changeDouble}
          />
        )
      })
    }
    <hr/>

  </div>

  );
}
export default DoublesForIdA;