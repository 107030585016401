import { Fragment } from "react";
import Header from '../components/UI/Header';
import StartingPageContent from '../components/UI/StartingPageContent';

const HomePage = () => {
  return (
    <Fragment>
     <Header/>
     <StartingPageContent />
   </Fragment>);
};

export default HomePage;
