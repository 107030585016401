//import React, { useContext, useState, useEffect } from "react";
import React, { useContext } from "react";
import AuthContext from "../../store/auth-context";

import "./Trickies.css";
import Tricky from "./Tricky";

const lblToValidate_multi = {"nl":"te valideren","fr":"à valider","de":"zu bestätigen"};
const lblIx_multi = {"nl":"Index","fr":"Indice","de":"Index"};
const lblSRC_address_multi = {"nl":"Adres Rijksregister","fr":"Adresse du Registre National","de":"Adresse des Nationalregisters"};
const lblBEST_address_multi = {"nl":"Adres BEST","fr":"Adresse BEST","de":"Adresse BEST"};
const lblBEST_idA_multi = {"nl":"Id Adres BEST","fr":"Id Adresse BEST","de":"Id Adresse BEST"};
const lblAccept_multi = {"nl":"Aanvaarden","fr":"Accepter","de":"Akzeptieren"};
const lblReject_multi = {"nl":"Verwerpen","fr":"Rejeter","de":"Ablehnen"};
const lblUpdate_multi = {"nl":"Status update","fr":"Statut de la màj","de":"Status-Update"};
const lblButtonUpdate_multi = {"nl":"Update","fr":"Update","de":"Update"};
const updMessage_multi = {"nl":"Verworpen mappings kunnen manueel worden gemapped in het Issues-scherm.", "fr":"Les mappings rejetés peuvent être mappés manuellement dans l'écran 'Issues'.","de":"Abgelehnte Mappings können im Bildschirm 'Issues' manuell zugeordnet werden."};
const adminCannotUpdateMsg_multi = {"nl":"Een admin gebruiker kan niet bijwerken.", "fr":"Un utilisateur admin ne peut pas mettre à jour.","de":"Ein Admin-Benutzer kann nicht aktualisieren."};
//const concurrencyMsg_multi = {"nl": "Het probleem is eerder vandaag bijgewerkt door ", "fr": "Le problème a été mis à jour plus tôt aujourd'hui par ", "de": "Das Problem wurde früher heute aktualisiert von "};

const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}

//----------------------------------------------------------------------------
function Trickies(props) {
const authCtx = useContext(AuthContext);
const domainBackend = authCtx.domainBackend;
const email = authCtx.email;
const token = authCtx.token;
const isAdmin = authCtx.isAdmin;
const issues = authCtx.issues;
const setIssues = authCtx.setIssues;
const trickies = authCtx.trickies;
const refreshTrickies = authCtx.refreshTrickies;
const setTrickies = authCtx.setTrickies;
const lan = authCtx.language;
const blockUpdates = authCtx.isInIncidentState || isAdmin;
const setPage = authCtx.setPage;
setPage("T"); //cfr Header.js

const authenticationString = createAuthenticationString(email, token);

const lblToValidate = lblToValidate_multi[lan];
const lblIx = lblIx_multi[lan];
const lblSRC_address = lblSRC_address_multi[lan];
const lblBEST_address = lblBEST_address_multi[lan];
const lblBEST_idA = lblBEST_idA_multi[lan];
const lblAccept = lblAccept_multi[lan];
const lblReject = lblReject_multi[lan];
const lblUpdate = lblUpdate_multi[lan];
const lblButtonUpdate = lblButtonUpdate_multi[lan];
const updMessage = updMessage_multi[lan];
const adminCannotUpdateMsg = adminCannotUpdateMsg_multi[lan];
//const concurrencyMsg = concurrencyMsg_multi[lan];

//----------------------------------------------------------------------------
const getLanguageString = (lan, snl, sfr, sde) => {
  if (lan === 'nl') { 
    if (snl) { return snl; }
    else if (sfr) { return sfr; }
    else { return sde; }
  };
  if (lan === 'fr') { 
    if (sfr) { return sfr; }
    else if (snl) { return snl; }
    else { return sde; }
  };
  if (lan === 'de') { 
    if (sde) { return sde; }
    else if (sfr) { return sfr; }
    else { return snl; }
  };
};
const getSRCstreetname = (tricky, lan) => {
  return getLanguageString(lan, tricky['Snl'], tricky['Sfr'], tricky['Sde']);
};
const getBESTstreetname = (tricky, lan) => {
  return getLanguageString(lan, tricky['Snl2'], tricky['Sfr2'], tricky['Sde2']);
};
const changeTricky = (ix, updStatus) => {
  const cloneTrickies = JSON.parse(JSON.stringify(trickies)); 
  cloneTrickies[ix]['treated'] = updStatus; 
  setTrickies(cloneTrickies);
};

//----------------------------------------------------------------------------
const updateTrickies = () => {
  console.log("updateTrickies");
  if (isAdmin) {
    alert(adminCannotUpdateMsg);
    return;
  };
 var cntUpdates = 0;
  for (let ixTricky = 0; ixTricky < trickies.length; ixTricky++) {
    if (trickies[ixTricky].treated === 'accept' || trickies[ixTricky].treated === 'reject') {
      console.log("trickies[ixTricky]", ixTricky, trickies[ixTricky]);
      cntUpdates += 1;
      const tricky = JSON.parse(JSON.stringify(trickies[ixTricky])); //deep copy

      updateRecord(tricky);
    }
  };
  const alertMessage = "Updating " + cntUpdates + " records\n" + updMessage;
  alert(alertMessage);
  refreshTrickies(); //see auth-context.js
};

//----------------------------------------------------------------------------------
async function updateRecord(tricky) {
  console.log("updateRecord", tricky);
  const ixIssue = tricky.ixIssue;
  const issue = issues[ixIssue];
  issue.warning = "Z2";
  if (tricky.treated === 'accept') {
    issue.action = "20";
    issue.treated = "1";
  };
  if (tricky.treated === 'reject') {
    issue.action = "19";
    issue.treated = "0";
  };
  console.log("UPDATING", issue);

  try {
    const requestOptions = {
    method: 'PUT',
    headers: { 
      'Authorization': authenticationString,
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify({'issue':issue})
    };

    console.log("requestOptions:", requestOptions);

    const response = await fetch(domainBackend + '/issue', requestOptions);
    const resData = await response.json();
    console.log("resData", resData);
    //'update OK', 'user unknown', 'token invalid', 'token expired - pls login again', 'invalid idM'

    if (resData.msg === 'Issue was updated earlier today') {
      console.log("Update failed - earlier update from another user");
      //let alertMsg = concurrencyMsg + resData.colleague + ' - timestamp (UTC): ' + resData.issue.upd;
      //alert(alertMsg);
      let updatedIssues = JSON.parse(JSON.stringify(issues)); //deep copy
      updatedIssues[ixIssue] = resData.issue;
      setIssues(updatedIssues);
    };

    if (resData.msg === 'update OK') {
      console.log("update OK");
      issue.upd = resData.upd;
      issue.user = email;

      let updatedIssues = JSON.parse(JSON.stringify(issues)); //deep copy
      updatedIssues[ixIssue] = issue;
      setIssues(updatedIssues);
      console.log("issues set");
    } else {
        alert(resData.msg);
    };
  } catch (err) {
    alert("Update problem in updateRecord(tricky)");
  };
};

//----------------------------------------------------------------------------
return (
    <div>
      <p>{trickies.length} Mappings {lblToValidate}</p>		
		  <button onClick={updateTrickies} disabled={blockUpdates} className='tricky_button'>{lblButtonUpdate}</button> 

      <table>
      <tbody>
        <tr className='header_row'>
          <td><label htmlFor="">{lblIx}</label></td>
          <td><label htmlFor="">{lblSRC_address}</label></td>
          <td><label htmlFor="">{lblBEST_address}</label></td>
          <td><label htmlFor="">{lblBEST_idA}</label></td>
          <td><label htmlFor="">{lblAccept}</label></td>
          <td><label htmlFor="">{lblReject}</label></td>
          <td><label htmlFor="">{lblUpdate}</label></td>
        </tr>
        {/* {trickies.slice(0, 50).map((tricky, index) => { */}
        {trickies.map((tricky, index) => {
          return (
                <Tricky
                ix = {index}
                S = {getSRCstreetname(tricky, lan)}
                hs = {tricky['hsOri']}
                bx = {tricky['bxOri']}
                idA = {tricky['idA']}
                S2 = {getBESTstreetname(tricky, lan)}
                hs2 = {tricky['hs2']}
                bx2 = {tricky['bx2']}
                updStatus = {tricky['treated']}
                changeTricky = {changeTricky}
                />
              )
            })
          }

        </tbody>
        </table>
    </div>

  );
}
export default Trickies;