import React from "react";

import "./DoubleRRN.css";


//----------------------------------------------------------------------------
function DoubleRRN(props) {
  
const handleAcceptRejectDouble = (evt) => {
  props.changeDouble(props.ix);
};

const handleRejectMapping = (evt) => {
  props.changeRejectMapping(props.ix);
};

const getLanguageString = (lan, snl, sfr, sde) => {
  if (lan === 'nl') { 
    if (snl) { return snl; }
    else if (sfr) { return sfr; }
    else { return sde; }
  };
  if (lan === 'fr') { 
    if (sfr) { return sfr; }
    else if (snl) { return snl; }
    else { return sde; }
  };
  if (lan === 'de') { 
    if (sde) { return sde; }
    else if (sfr) { return sfr; }
    else { return snl; }
  };
};

const streetname = getLanguageString(props.lan, props.Snl, props.Sfr, props.Sde);

//----------------------------------------------------------------------------
return (
  <section className="gridDoubles">
    <label htmlFor="" className="box-double-idA">{props.keyRR} (RRN)</label>
    <label htmlFor="" className="box-double-postcode">{props.POri}</label>
    <label htmlFor="" className="box-double-streetcode">{props.idS_SRC}</label>
    <label htmlFor="" className="box-double-street">{streetname}</label>
    <label htmlFor="" className="box-double-hs">{props.hsOri}</label>
    <label htmlFor="" className="box-double-bx">{props.bxOri}</label>
    <input type="radio" name={props.idA} checked={(props.updStatus === "accept double" ? true : false )} disabled={(props.updStatus === "reject mapping" ? true : false )} onClick={handleAcceptRejectDouble} className="box-double-radioButton"/>
    <input type="checkbox" value={(props.updStatus === "reject mapping" ? true : false )} onChange={handleRejectMapping} className="box-double-checkBox"/>
    <label htmlFor="" className="box-double-updStatus">{props.updStatus}</label>
  </section>

  );
}
export default DoubleRRN;