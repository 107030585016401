import React from "react";
import "./Stats.css";
const hdr_multi = {"nl":"Statistieken","fr":"Statistiques","de":"Statistiken"};
const lblIssueType_multi = {"nl":"Soort probleem","fr":"Type de problème","de":"Art von Problem"};
const lblTotal_multi = {"nl":"Totaal","fr":"Total","de":"Gesamt"};
const lblOpen_multi = {"nl":"Open Issue","fr":"Ouvert Issue","de":"Offen Issue"};
const lblOpenTricky_multi = {"nl":"Open Tricky","fr":"Ouvert Tricky","de":"Offen Tricky"};
const lblOpenDouble_multi = {"nl":"Open Dubbel","fr":"Ouvert Double","de":"Offen Doppelt"};
const lblTreated_multi = {"nl":"Behandeld","fr":"Traité","de":"Behandelt"};
const lblTotal2_multi = {"nl":"Totaal : ","fr":"Total : ","de":"Gesamt : "};
const lblPostcodeCorrections_multi = {"nl":"Correcties BPOST", "fr":"Corrections BPOST", "de":"Korrekturen BPOST"}
const lblB1_multi = {"nl":"B1. Straat is niet relevant : ","fr":"B1. Rue irrelevante : ","de":"B1. Straße irrelevant : "};
const lblB2_multi = {"nl":"B2. Straat mapping : ","fr":"B2. Mapping rue : ","de":"B2. Mapping der Straße : "};
const lblB3_multi = {"nl":"B3. Incoherente postcode : ","fr":"B3. Code postal incohérent : ","de":"B3. Inkohärente Postleitzahl : "};
const lblC1_multi = {"nl":"C1. Straat zonder huisnummers in BEST : ","fr":"C1. Rue sans numéros de maison dans BEST : ","de":"C1. Straße ohne Hausnummern in BEST : "};
const lblC2_multi = {"nl":"C2. Huisnummers verschillen : ","fr":"C2. Différents numéros de maison : ","de":"C2. Unterschiedliche Hausnummern : "};
const lblC3_multi = {"nl":"C3. Ambigue straten : ","fr":"C3. Rues ambiguës : ","de":"C3. Mehrdeutige Straßen : "};
const lblD1_multi = {"nl":"D1. Busnummer ontbreekt in BEST : ","fr":"D1. Numéro de boîte manquant dans BEST : ","de":"D1. Boxnummer fehlt in BEST : "};
const lblD2_multi = {"nl":"D2. Busnummers verschillen : ","fr":"D2. Différents numéros de boîte : ","de":"D2. Unterschiedliche Boxnummern : "};
const lblD3_multi = {"nl":"D3. Busnummer ontbreekt in de bron : ","fr":"D3. Le numéro de boîte est manquant dans la source : ","de":"D3. Die Boxnummer fehlt in der Quelle : "};
const lblZ2_multi = {"nl":"Z2. Tricky adressen : ","fr":"Z2. Tricky adresses : ","de":"Z2. Tricky Adressen : "};
const lblZ3_multi = {"nl":"Z3. Dubbele adressen : ","fr":"Z3. Adresses en double : ","de":"Z3. Doppelte Adressen : "};

function Stats(props) {
  const lan = props.language;
  const issues = props.issues;

  const hdr = hdr_multi[lan];
  const lblIssueType = lblIssueType_multi[lan];
  const lblTotal = lblTotal_multi[lan];
  const lblOpen = lblOpen_multi[lan];
  const lblOpenTricky = lblOpenTricky_multi[lan];
  const lblOpenDouble = lblOpenDouble_multi[lan];
  const lblTreated = lblTreated_multi[lan];
  const lblTotal2 = lblTotal2_multi[lan];
  const lblPostcodeCorrections = lblPostcodeCorrections_multi[lan];
  const lblB1 = lblB1_multi[lan];
  const lblB2 = lblB2_multi[lan];
  const lblB3 = lblB3_multi[lan];
  const lblC1 = lblC1_multi[lan];
  const lblC2 = lblC2_multi[lan];
  const lblC3 = lblC3_multi[lan];
  const lblD1 = lblD1_multi[lan];
  const lblD2 = lblD2_multi[lan];
  const lblD3 = lblD3_multi[lan];
  const lblZ2 = lblZ2_multi[lan];
  const lblZ3 = lblZ3_multi[lan];

  var BPOST = 0;
  var B1 = 0;
  var B2 = 0;
  var B3 = 0;
  var C1 = 0;
  var C2 = 0;
  var C3 = 0;
  var D1 = 0;
  var D2 = 0;
  var D3 = 0;
  var Z2 = 0;
  var Z3 = 0;
  var total_open = 0;
  var B1_open = 0;
  var B2_open = 0;
  var B3_open = 0;
  var C1_open = 0;
  var C2_open = 0;
  var C3_open = 0;
  var D1_open = 0;
  var D2_open = 0;
  var D3_open = 0;
  var Z2_open = 0;
  var Z2_openIssues = 0;
  var Z2_openTricks = 0;
  var Z2_closed = 0;
  var Z3_open = 0;
  var Z3_openIssues = 0;
  var Z3_openDoubles = 0;
  var Z3_closed = 0;

  issues.forEach(function(element) { 
    if (element.warning === "" && element.user === "BPOST") { 
      BPOST = BPOST + 1;
    };
    if (element.warning === "B1") { 
      B1 = B1 + 1;
      if (element.treated === '0') {B1_open = B1_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "B2") { 
      B2 = B2 + 1;
      if (element.treated === '0') {B2_open = B2_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "B3") {
        B3 = B3 + 1;
        if (element.treated === '0') {B3_open = B3_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "C1") {
        C1 = C1 + 1;
        if (element.treated === '0') {C1_open = C1_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "C2") {
        C2 = C2 + 1;
        if (element.treated === '0') {C2_open = C2_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "C3") {
        C3 = C3 + 1;
        if (element.treated === '0') {C3_open = C3_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "D1") {
        D1 = D1 + 1;
        if (element.treated === '0') {D1_open = D1_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "D2") {
        D2 = D2 + 1;
        if (element.treated === '0') {D2_open = D2_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "D3") {
        D3 = D3 + 1;
        if (element.treated === '0') {D3_open = D3_open + 1; total_open = total_open +1;}
    };
    if (element.warning === "Z2" || element.action === "02") {
        Z2 = Z2 + 1;
        if (element.treated === '0') {Z2_open = Z2_open + 1; total_open = total_open +1;};
        if (element.treated === '0' && element.action === "19") {Z2_openIssues = Z2_openIssues + 1;};
        if (element.action === "02") {Z2_openTricks = Z2_openTricks + 1;};
        //if (element.treated === '1' && element.action !== "19") {Z2_closed = Z2_closed + 1;};
        if (element.treated === '1') {Z2_closed = Z2_closed + 1;};
    };
    if (element.warning === "Z3" || element.action === "03"|| element.action === "04"|| element.action === "05") {
    //if (element.warning === "Z3" || element.action in ["03", "04", "05"]) {
        Z3 = Z3 + 1;
      if (element.treated === '0') {Z3_open = Z3_open + 1; total_open = total_open +1;}
      if (element.treated === '0' && element.action === "19") {Z3_openIssues = Z3_openIssues + 1;};
      if (element.action === "03") {Z3_openDoubles = Z3_openDoubles + 1;};
      if (element.treated === '1') {Z3_closed = Z3_closed + 1;};
    };
});
const total11 = issues.length;
const total12 = total_open;


  return (
  <div className="stats">
	  <h1 className="heading">{hdr}</h1>
    <table className="tableStats">
      <tbody>
      <tr>
        <th>{lblIssueType}</th>
        <th>{lblTotal}</th>
        <th>{lblOpen}</th>
        <th>{lblOpenTricky}</th>
        <th>{lblOpenDouble}</th>
        <th>{lblTreated}</th>
      </tr>
      <tr>
        <td>{lblTotal2}</td>
        <td>{total11}</td>
        <td>{total12}</td>
        <td>{Z2_openTricks}</td>
        <td>{Z3_openDoubles}</td>
        <td>{total11 - total12 - Z2_openTricks - Z3_openDoubles}</td>
      </tr>
      <tr>
        <td>{lblPostcodeCorrections}</td>
        <td>{BPOST}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{BPOST}</td>
      </tr>
      <tr>
        <td>{lblB1}</td>
        <td>{B1}</td>
        <td>{B1_open}</td>
        <td></td>
        <td></td>
        <td>{B1 - B1_open}</td>
      </tr>
      <tr>
        <td>{lblB2}</td>
        <td>{B2}</td>
        <td>{B2_open}</td>
        <td></td>
        <td></td>
        <td>{B2 - B2_open}</td>
      </tr>
      <tr>
        <td>{lblB3}</td>
        <td>{B3}</td>
        <td>{B3_open}</td>
        <td></td>
        <td></td>
        <td>{B3 - B3_open}</td>
      </tr>
      <tr>
        <td>{lblC1}</td>
        <td>{C1}</td>
        <td>{C1_open}</td>
        <td></td>
        <td></td>
        <td>{C1 - C1_open}</td>
      </tr>
      <tr>
        <td>{lblC2}</td>
        <td>{C2}</td>
        <td>{C2_open}</td>
        <td></td>
        <td></td>
        <td>{C2 - C2_open}</td>
      </tr>
      <tr>
        <td>{lblC3}</td>
        <td>{C3}</td>
        <td>{C3_open}</td>
        <td></td>
        <td></td>
        <td>{C3 - C3_open}</td>
      </tr>
      <tr>
        <td>{lblD1}</td>
        <td>{D1}</td>
        <td>{D1_open}</td>
        <td></td>
        <td></td>
        <td>{D1 - D1_open}</td>
      </tr>
      <tr>
        <td>{lblD2}</td>
        <td>{D2}</td>
        <td>{D2_open}</td>
        <td></td>
        <td></td>
        <td>{D2 - D2_open}</td>
      </tr>
      <tr>
        <td>{lblD3}</td>
        <td>{D3}</td>
        <td>{D3_open}</td>
        <td></td>
        <td></td>
        <td>{D3 - D3_open}</td>
      </tr>
      <tr>
        <td>{lblZ2}</td>
        <td>{Z2}</td>
        <td>{Z2_openIssues}</td>
        <td>{Z2_openTricks}</td>
        <td></td>
        <td>{Z2_closed}</td>
      </tr>
      <tr>
        <td>{lblZ3}</td>
        <td>{Z3}</td>
        <td>{Z3_openIssues}</td>
        <td></td>
        <td>{Z3_openDoubles}</td>
        <td>{Z3_closed}</td>
      </tr>
     </tbody>
  </table>



  </div>
  );
}
export default Stats;