import { Fragment } from "react";
import Header from '../components/UI/Header';
import ChangePasswordForm from '../components/Auth/ChangePasswordForm';

const ChangePasswordPage = () => {
  return (
    <Fragment>
     <Header/>
     <ChangePasswordForm />
   </Fragment>);
};

export default ChangePasswordPage;
