import React, { useState } from "react";
const src_nl = process.env.REACT_APP_SRC_NL;
const src_fr = process.env.REACT_APP_SRC_FR;
const src_de = process.env.REACT_APP_SRC_DE;
const streetSplitLimit = parseInt(process.env.REACT_APP_STREET_SPLIT_LIMIT);

const domainBackendDEV = process.env.REACT_APP_DOMAIN_BACKEND_DEV;
const domainBackendPROD = process.env.REACT_APP_DOMAIN_BACKEND_PROD;
const reactEnv = process.env.NODE_ENV;
let isCompiled = false;
if (reactEnv === "production") {isCompiled = true};
let phase = process.env.REACT_APP_PHASE; //TEST or PROD

let domainBackend = "";
if (isCompiled) { 
  phase = phase + ' (compiled)';
  domainBackend = domainBackendPROD;
} else { 
  phase = phase + ' (not compiled)'; 
  domainBackend = domainBackendDEV;
};
console.log('isCompiled:', isCompiled, 'reactEnv:', reactEnv, 'phase:', phase, 'domainBackend:', domainBackend);


//-------------------------------------------------------------------------------------------------------------

const AuthContext = React.createContext({
  token: "",
  email: "",
  isFirstTime: false,
  isLoggedIn: false,
  isAdmin: false,
  NIS: "",
  idM: "",
  Mnl:"",
  Mfr:"",
  Mde:"",
  language: "nl",
  login: (token, email, isAdmin, NIS, idM, Mnl, Mfr, Mde) => {},
  handleIsFirstTime: (bool) => {},
  splitIssuesTrickiesDoubles: (records) => {},
  setIsNotFirstTime: () => {},
  selectMunicipality: (NIS, idM, Mnl, Mfr, Mde) => {},
  logout: () => {},
  src_nl: src_nl,
  src_fr: src_fr,
  src_de: src_de,
  streetSplitLimit: streetSplitLimit,
  phase: phase,
  domainBackend: domainBackend,
  setLanguage: (language) => {},
  setMunicipalities: () => {},
  municipalities: {},
  setIssues: () => {},
  setTrickies: () => {},
  refreshTrickies: () => {},
  setDoubles: () => {},
  updDoublesForIdA: (idA, lstForIdA) => {},
  refreshDoubles: () => {},

  updIssueInContext: (issue) => {},
  updLstOfIssuesInContext:(lstOfUpdatedIssues) => {},
  updTrickyInContext: (tricky) => {},
  updDoubleInContext: (double) => {},

  issues: [],
  trickies: [],
  doubles: {},
  
  mapStreetnamesNL: [],
  mapStreetnamesFR: [] ,
  mapStreetnamesDE: [] ,
  streetIds: {} ,
  streetnameDic: {} ,
  streetObjIdToIdS: {} ,

  setMapStreetnamesNL: () => {},
  setMapStreetnamesFR: () => {},
  setMapStreetnamesDE: () => {},
  setStreetIds: () => {},
  setStreetnameDic: () => {},
  setStreetObjIdToIdS: () => {},

  mapNumbers: {},
  setMapNumbers: () => {},
  page: "", //I=issues, T=trickies, D=doubles, default ""
  setPage: () => {},
  fetchIssues: () => {},
  fetchStreetnames: () => {},
  fetchNumbers: () => {},
  createAuthenticationString: () => {},
  isInIncidentState: false,
  handleIncident: () => {}
});

//-------------------------------------------------------------------------------------------------------------

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [NIS, setNIS] = useState("");
  const [idM, setIdM] = useState("");
  const [Mnl, setMnl] = useState("");
  const [Mfr, setMfr] = useState("");
  const [Mde, setMde] = useState("");
  const [language, setLanguage] = useState("nl");
  const userIsLoggedIn = !!token;
  const [municipalities, setMunicipalities] = useState({});
  const [issues, setIssues] = useState([]);
  const [trickies, setTrickies] = useState([]);
  const [doubles, setDoubles] = useState({});

  const loginHandler = (token, email, isAdmin, NIS, idM, Mnl, Mfr, Mde) => {
      setToken(token);
      setEmail(email);
      setIsAdmin(isAdmin);
      setNIS(NIS);
      setIdM(idM);
      setMnl(Mnl);
      setMfr(Mfr);
      setMde(Mde);
  };
  const selectMunicipality = (NIS, idM, Mnl, Mfr, Mde) => {
    setNIS(NIS);
    setIdM(idM);
    setMnl(Mnl);
    setMfr(Mfr);
    setMde(Mde);
};
  const logoutHandler = () => {
    setToken(null);
    setEmail("");
    setIsAdmin(false);
    setNIS("");
    setIdM("");
    setMnl("");
    setMfr("");
    setMde("");
  };
  const languageHandler = (language) => {
    setLanguage(language);
  };
  const handleIsFirstTime = (bool) => {
    setIsFirstTime(bool);
  };
  const splitIssuesTrickiesDoubles = (records) => {
    const dataIssues = [];
    const dataTrickies = [];
    const dataDoubles = {};
    let ix = 0;
    for (const record of records) {
      if (record.action === '02') {
        record['treated'] = 'todo'; //'0' => 'todo' =>  'accept'/'reject' => '1'
        record['ixIssue'] = ix;
        dataTrickies.push(record);
      } else if (record.action === '03') {
        const idA = record.idA;
        record['treated'] = 'todo'; //'0' => 'todo' =>  'accept'/'reject mapping'/'reject double' => '1'
        record['ixIssue'] = ix;
        if (idA in dataDoubles) {
          dataDoubles[idA].push(record);
        } else {
          dataDoubles[idA] = [record];
        };
      };
      dataIssues.push(record);
      ix = ix + 1;
    };
    setIssues(dataIssues);
    setTrickies(dataTrickies);
    setDoubles(dataDoubles);
  };

  const updIssueInContext = (issue) => {
    const dataIssues = [];
    const newId = issue._id;
    for (let i = 0; i < issues.length; i++) {
      const oldIssue = issues[i];
      const oldId = oldIssue._id;
      if (newId === oldId) {
        dataIssues.push(issue);
      } else {
        dataIssues.push(oldIssue);
      };
    };
    //update issues in auth-context
    setIssues(dataIssues);
  };

  const updTrickyInContext = (tricky) => {
    //update issues in auth-context
    updIssueInContext(tricky);

    const dataTrickies = [];
    const newId = tricky._id;
    for (let i = 0; i < trickies.length; i++) {
      const oldTricky = trickies[i];
      const oldId = oldTricky._id;
      if (newId !== oldId) {
        dataTrickies.push(oldTricky);
      };
    };
     // update trickies in auth-context
    setTrickies(dataTrickies);
  };

  const updDoubleInContext = (issue) => {
    //update issues in auth-context
    updIssueInContext(issue);

    const dataDoubles = JSON.parse(JSON.stringify(doubles)); //deep copy
    const idA = issue.idA;
    const newId = issue._id;

    const oldLstForIdA = dataDoubles[idA];
    const newLstForIdA = [];
    for (let i = 0; i < oldLstForIdA.length; i++) {
      const oldDouble = oldLstForIdA[i];
      const oldId = oldDouble._id;
      if (newId !== oldId) {
        newLstForIdA.push(oldDouble);
      };
    };
    if (newLstForIdA.length > 0) {
      dataDoubles[idA] = newLstForIdA;
      console.log("authCtx: newLstForIdA", dataDoubles[idA]);
      setDoubles(dataDoubles);
      console.log("authCtx: setDoubles to dataDoubles", dataDoubles);
    } else {
      console.log("remove key from doubles");
      var filtered = Object.fromEntries(Object.entries(dataDoubles).filter(([k,v]) => k !== idA));
      setDoubles(filtered);
      console.log("authCtx: setDoubles to filtered dataDoubles", filtered);
    };
    // update doubles in auth-context
  };

  const refreshTrickies = () => {
    const dataTrickies = [];
    let ix = 0;
    for (const record of issues) {
      if (record.action === '02') {
        record['treated'] = 'todo'; //'' => 'todo' =>  'accept'/'reject' => 'updating' => timestamp update
        record['ixIssue'] = ix;
        dataTrickies.push(record);
      };
      ix = ix + 1;
    };
    setTrickies(dataTrickies);
  };

  const updDoublesForIdA = (idA, lstForIdA) => {
    let newDoubles = JSON.parse(JSON.stringify(doubles));
    newDoubles[idA] = lstForIdA;
    setDoubles(newDoubles);
    console.log("authCtx: updDoublesForIdA", idA, newDoubles[idA], newDoubles);
    // setDoubles((prevState) => ({
    //   ...prevState,
    //   idA: lstForIdA,
    // }));
  };

  const refreshDoubles = () => {
    const dataDoubles = {};
    let ix = 0;
    for (const record of issues) {
      if (record.action === '03') {
        record['treated'] = 'todo'; //'' => 'todo' =>  'accept'/'reject' => 'updating' => timestamp update
        record['ixIssue'] = ix;
        const idA = record['idA'];
        if (idA in dataDoubles) {
          dataDoubles[idA].push(record);
        } else {
          dataDoubles[idA] = [record];
        };
      };
      ix = ix + 1;
    };
    setDoubles(dataDoubles);
  };

  const [mapStreetnamesNL, setMapStreetnamesNL] = useState([]);
  const [mapStreetnamesFR, setMapStreetnamesFR] = useState([]);
  const [mapStreetnamesDE, setMapStreetnamesDE] = useState([]);
  const [streetIds, setStreetIds] = useState({});
  const [streetnameDic, setStreetnameDic] = useState({});
  const [streetObjIdToIdS, setStreetObjIdToIdS] = useState({});

  const [mapNumbers,setMapNumbers] = useState({});
  const [page, setPage] = useState("");
  const [isInIncidentState, setIsInIncidentState] = useState(false);
  const handleIncident = (isInIncident) => {
    setIsInIncidentState(isInIncident)
  };
  const fetchIssues = async (Nis, parEmail, parToken) => {
    console.log("auth-context: fetchIssues (Nis)", Nis);
    if (Nis) {
      const authenticationString = createAuthenticationString(parEmail, parToken);
      try {
          const requestOptions = {
              method: 'GET',
              headers: { 
                  'Authorization': authenticationString,
                  'Content-Type': 'application/json' 
              },
          };

          const response = await fetch(domainBackend + '/issues?NIS='+Nis, requestOptions);
          const resData = await response.json();
          console.log('resData', resData);
          if (!response.ok) {
                  throw new Error('Fetching the issues failed.');
          };
          const lstToBeAdded = Object.entries(resData['issues']).map((iss, index) => {
              var dicIssue = iss[1];
              return({_id:dicIssue['_id'], idM_SRC:dicIssue['idM_SRC'], idS_SRC:dicIssue['idS_SRC'], 
              Mnl:dicIssue['Mnl'], Mfr:dicIssue['Mfr'], Mde:dicIssue['Mde'], POri:dicIssue['POri'], P:dicIssue['P'], Snl:dicIssue['Snl'], 
              Sfr:dicIssue['Sfr'], Sde:dicIssue['Sde'], hs:dicIssue['hs'], bx:dicIssue['bx'], idA:dicIssue['idA'], R:dicIssue['R'], idM:dicIssue['idM'], 
              idS:dicIssue['idS'], P2:dicIssue['P2'], P3:dicIssue['P3'], hs2:dicIssue['hs2'], bx2:dicIssue['bx2'], pc:dicIssue['pc'],
              pc2:dicIssue['pc2'], warning:dicIssue['warning'], action:dicIssue['action'], treated:dicIssue['treated'], upd:dicIssue['upd'],
              hsOri:dicIssue['hsOri'], bxOri:dicIssue['bxOri'], Mnl2:dicIssue['Mnl2'], Mfr2:dicIssue['Mfr2'], Mde2:dicIssue['Mde2'], 
              Snl2:dicIssue['Snl2'], Sfr2:dicIssue['Sfr2'], Sde2:dicIssue['Sde2'], pcM:dicIssue['pcM'], pcS:dicIssue['pcS'], 
              pcS2:dicIssue['pcS2'], pcHs:dicIssue['pcHs'], pcBx:dicIssue['pcBx'], lstP2:dicIssue['lstP2'], lstIdS:dicIssue['lstIdS'], trick:dicIssue['trick'], 
              user:dicIssue['user'], comment:dicIssue['comment'], Pbpost:dicIssue['Pbpost']
              });
          });
          console.log("splitIssuesTrickiesDoubles", lstToBeAdded);
          splitIssuesTrickiesDoubles(lstToBeAdded);
          } catch (err) {
              alert(err.message);
          }
      };
    };
//----------------------------------------------------------------------
const fetchStreetnames = async (idM, parEmail, parToken) => {
  console.log("auth-context: fetchStreetnames", idM);
  if (idM) {
    const authenticationString = createAuthenticationString(parEmail, parToken);
    const region = idM.charAt(0);
      try {
          const requestOptions = {
              method: 'GET',
              headers: { 
                  'Authorization': authenticationString,
                  'Content-Type': 'application/json' 
              },
          };

          const response = await fetch(domainBackend + '/streetnames?idM='+idM, requestOptions);
          const resData = await response.json();
          if (!response.ok) {
              throw new Error('Fetching the streetnames failed.');
          }
          //console.log('resData', resData);

          const lstNL = [];
          const lstFR = [];
          const lstDE = [];
          const dicToBeAdded = {};
          const dic2ToBeAdded = {};
          const dic3ToBeAdded = {};

          for (const [key, value] of Object.entries(resData)) {
              if (region === "F" ) {
                  lstNL.push([value.nl, key]);
                  lstFR.push([value.nl, key]);
                  lstDE.push([value.nl, key]);
              };
              if (region === "B" ) {
                  lstNL.push([value.nl, key]);
                  lstFR.push([value.fr, key]);
                  lstDE.push([value.nl, key]);
              };
              if (region === "W") {
                  if (("fr" in value) && ("de" in value)) {
                      lstNL.push([value.de, key]);
                      lstFR.push([value.fr, key]);
                      lstDE.push([value.de, key]);
                  } else {
                      if ("fr" in value) {
                          lstNL.push([value.fr, key]);
                          lstFR.push([value.fr, key]);
                          lstDE.push([value.fr, key]);
                      } else { //only 'de' name
                          lstNL.push([value.de, key]);
                          lstFR.push([value.de, key]);
                          lstDE.push([value.de, key]);
                      };
                  };							
              };
              if ("nl" in value) {dicToBeAdded[key]=value.nl;}
              else {
                  if ("fr" in value) {dicToBeAdded[key]=value.fr;}
                  else {
                      if ("de" in value) {dicToBeAdded[key]=value.de;}
                  }
              };
              if (key in dic2ToBeAdded) {} else { dic2ToBeAdded[key]={}};
              if ("nl" in value) {dic2ToBeAdded[key]['nl']=value['nl']};
              if ("fr" in value) {dic2ToBeAdded[key]['fr']=value['fr']};
              if ("de" in value) {dic2ToBeAdded[key]['de']=value['de']};

              const lst = key.split('_');
              const objId = lst[1];
              dic3ToBeAdded[objId] = key;

          };
          lstNL.sort();
          lstFR.sort();
          lstDE.sort();

          setMapStreetnamesNL(lstNL);
          setMapStreetnamesFR(lstFR);
          setMapStreetnamesDE(lstDE);
          setStreetIds(dicToBeAdded);
          setStreetnameDic(dic2ToBeAdded);

          setStreetObjIdToIdS(dic3ToBeAdded);
      } catch (err) {
          alert(err.message);
      }
  };
};

//----------------------------------------------------------------------
const fetchNumbers = async (idM, parEmail, parToken) => {
  console.log("auth-context: fetchNumbers", idM);
  if (idM) {
      const authenticationString = createAuthenticationString(parEmail, parToken);

      try {
          var nextIdM = 0;
          const requestOptions = {
              method: 'GET',
              headers: { 
                  'Authorization': authenticationString,
                  'Content-Type': 'application/json' 
              },
          };

          const response = await fetch(domainBackend + '/numbers?idM='+idM, requestOptions);
          const resData = await response.json();
          if (!response.ok) {
                  throw new Error('Fetching the numbers failed.');
          }
          var dic = {};
          Object.entries(resData).map((street, index) => {
              //dic[street[0]]=street[1]; //street[0] is the full idS, street[1] is a complex number structure
              const lst = street[0].split('_');
              const sleutel = lst[0] + '_' + lst[1]; //drop version - why ? there are street version differences e.g. Antwerpen: Entrepotplaats 
              dic[sleutel]=street[1]; 
              return null;
          });

          if (Object.keys(dic).length === streetSplitLimit) {
              nextIdM = nextIdM + 1;
          } else {
              setMapNumbers(dic);	
          };

          while (nextIdM > 0) {
              console.log("auth-context: fetchNumbers: get additional numbers", nextIdM);

              const requestOptions = {
                  method: 'GET',
                  headers: { 
                      'Authorization': authenticationString,
                      'Content-Type': 'application/json' 
                  },
              };
              const response = await fetch(domainBackend + '/numbers?idM='+idM+'_'+nextIdM.toString(), requestOptions);
              const resData = await response.json();
              if (!response.ok) {
                  throw new Error('Fetching the additional numbers failed.');
              }
              Object.entries(resData).map((street, index) => {
                  var lstStreet = street[0].split('_');
                  var idStreet = lstStreet[0] + '_' + lstStreet[1];
                  dic[idStreet]=street[1];
                  return null;
              });

              if (Object.keys(dic).length === streetSplitLimit * (nextIdM + 1)) {
                  nextIdM = nextIdM + 1;
              } else if (nextIdM > 0) {
                  setMapNumbers(dic);	
                  nextIdM=0;
              };
          };
      } catch (err) {
          alert(err.message);
      };
  };
};

const createAuthenticationString = (email, token) => {
  let authString = email + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
};
//-------------------------------------------------------------------------------------------------------------

  const contextValue = {
    token: token,
    email: email,
    isLoggedIn: userIsLoggedIn,
    isFirstTime: isFirstTime,
    isAdmin: isAdmin,
    NIS: NIS,
    idM: idM,
    Mnl: Mnl,
    Mfr: Mfr,
    Mde: Mde,
    language: language,  
    login: loginHandler,
    logout: logoutHandler,
    selectMunicipality: selectMunicipality,
    src_nl: src_nl,
    src_fr: src_fr,
    src_de: src_de,
    streetSplitLimit: streetSplitLimit,
    phase: phase,
    domainBackend: domainBackend,
    setLanguage: languageHandler,
    handleIsFirstTime: handleIsFirstTime,
    splitIssuesTrickiesDoubles: splitIssuesTrickiesDoubles,
    setMunicipalities: setMunicipalities,
    municipalities: municipalities,

    setIssues: setIssues,
    setTrickies: setTrickies,
    refreshTrickies: refreshTrickies,
    setDoubles: setDoubles,
    updDoublesForIdA: updDoublesForIdA,
    refreshDoubles: refreshDoubles,
    issues: issues,
    trickies: trickies,
    doubles: doubles,

    updIssueInContext: updIssueInContext,
    updTrickyInContext: updTrickyInContext,
    updDoubleInContext: updDoubleInContext,

    mapStreetnamesNL: mapStreetnamesNL,
    mapStreetnamesFR: mapStreetnamesFR ,
    mapStreetnamesDE: mapStreetnamesDE ,
    streetIds: streetIds ,
    streetnameDic: streetnameDic ,
    streetObjIdToIdS: streetObjIdToIdS ,
  
    setMapStreetnamesNL: setMapStreetnamesNL,
    setMapStreetnamesFR: setMapStreetnamesFR,
    setMapStreetnamesDE: setMapStreetnamesDE,
    setStreetIds: setStreetIds,
    setStreetnameDic: setStreetnameDic,
    setStreetObjIdToIdS: setStreetObjIdToIdS,

    mapNumbers: mapNumbers,
    setMapNumbers: setMapNumbers,

    page: page,
    setPage: setPage,
    createAuthenticationString: createAuthenticationString,
    fetchIssues: fetchIssues,
    fetchStreetnames: fetchStreetnames,
    fetchNumbers: fetchNumbers,
    isInIncidentState: isInIncidentState,
    handleIncident: handleIncident
  };


  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;