import { Fragment } from "react";
import Header from '../components/UI/Header';
import Issues from '../components/UI/Issues';

const IssuesPage = () => {
  return (
   <Fragment>
    <Header/>
    <Issues />
  </Fragment>);
};

export default IssuesPage;
