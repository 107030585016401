import { Fragment } from "react";
import Header from '../components/UI/Header';
import DownloadForm from '../components/UI/DownloadForm';

const DownloadPage = () => {
  return (
    <Fragment>
     <Header/>
     <DownloadForm />
   </Fragment>);
};

export default DownloadPage;
