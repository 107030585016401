import React from "react";
import "./Decision.css";
//var lblDecision1 = {"nl":"(1) De mapping voorgesteld door BOSA is correct","fr":"(1) Le mapping proposé par BOSA est correct","de":"(1) Die von BOSA vorgeschlagene Zuordnung ist korrekt"};
const lblDecision1_multi = {"nl":"(1) De mapping voorgesteld door de gemeente is correct.","fr":"(1) Le mapping proposé par la commune est correct.","de":"(1) Die von der Gemeinde vorgeschlagene Zuordnung ist korrekt."};
const lblDecision2_multi = {"nl":"(2) Het bron adres moet worden verbeterd in de bron.","fr":"(2) L'adresse source doit être corrigée dans la source.","de":"(2) Die Quelladresse muss in der Quelle korrigiert werden."};
const lblDecision3_multi = {"nl":"(3) Het bron adres ontbreekt in BEST en moet worden toegevoegd in BEST.","fr":"(3) L'adresse source est absente en BEST et doit être ajoutée dans BEST.","de":"(3) Die Quelladresse fehlt in BEST und muss in BEST hinzugefügt werden."};
const lblDecision4_multi = {"nl":"(4) Het bron  adres moet worden verwijderd uit de bron.","fr":"(4) L'adresse source doit être supprimée dans la source.","de":"(4) Die Quelladresse muss in der Quelle entfernt werden."};
const lblComment_multi = {"nl":"Beslissingen (2) en (3) vergen aparte aanpassingen buiten dit programma","fr":"Les décisions (2) et (3) nécessitent des ajustements séparés en dehors de ce programme","de":"Die Entscheidungen (2) und (3) erfordern separate Anpassungen außerhalb dieses Programms"};
const lblCommentArea_multi = {"nl":"Commentaar bij (2) of (3): ","fr":"Commentaire pour (2) ou (3): ","de":"Kommentar für (2) oder (3): "};
const hdr_multi = {"nl":"Beslissing :","fr":"Décision :","de":"Entscheidung :"};

function Decision(props) {
  const lan = props.language;
  const lblDecision1 = lblDecision1_multi[lan];
  const lblDecision2 = lblDecision2_multi[lan];
  const lblDecision3 = lblDecision3_multi[lan];
  const lblDecision4 = lblDecision4_multi[lan];
  const lblComment = lblComment_multi[lan];
  const lblCommentArea = lblCommentArea_multi[lan];
  const hdr = hdr_multi[lan];

	return (
	<div>
 		<h3 className="mappingTitle">{hdr}</h3>

		<form>
        <table>
          <tbody>
            <tr>
              <td><label htmlFor="decision">{lblDecision1}</label></td>
              <td><input type="radio" id="decision1" name="decision" value="1" checked={props.decision === "1"} onChange={props.onDecision}/></td>
            </tr>

            <tr>
              <td><label htmlFor="decision">{lblDecision2}</label></td>
              <td><input type="radio" id="decision2" name="decision" value="2"checked={props.decision === "2"} onChange={props.onDecision}/></td>
            </tr>

            <tr>
              <td><label htmlFor="decision">{lblDecision3}</label></td>
              <td><input type="radio" id="decision3" name="decision" value="3" checked={props.decision === "3"} onChange={props.onDecision}/></td>
            </tr>

            <tr>
              <td><label htmlFor="decision">{lblDecision4}</label></td>
              <td><input type="radio" id="decision4" name="decision" value="4" checked={props.decision === "4"} onChange={props.onDecision}/></td>
            </tr>

          </tbody>
        </table>

      </form>  
    {lblComment}
    <br/>
    <label className="warningMsg">{props.warningPostcode}</label>
    <br/>
    <label htmlFor="comment">{lblCommentArea}</label>
    <textarea rows="3" cols="70" id="comment" name="comment" value={props.comment} disabled={props.decision !== "2" && props.decision !== "3"} onChange={props.onComment}/>

	</div>
	);
}
export default Decision;