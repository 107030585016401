import { Fragment } from "react";
import Header from '../components/UI/Header';
import Trickies from '../components/UI/Trickies';

const TrickiesPage = () => {
  return (
    <Fragment>
     <Header/>
     <Trickies />
   </Fragment>); 
};

export default TrickiesPage;
