import React, { useContext } from "react";
import AuthContext from "../../store/auth-context";

import "./Header.css";

const hdr_multi = {"nl":"BEST: Straten en Adressen","fr":"BEST: Rues et Adresses","de":"BEST: Straßen und Adressen"};
const lblVersion_multi = {"nl":"Versie: ","fr":"Version: ","de":"Version: "};
const lblUser_multi = {"nl":"Gebruiker: ","fr":"Utilisateur: ","de":"Benutzer: "};
const page_multi = {"I": {"nl": "Issues", "fr": "Issues", "de": "Issues"},
"T": {"nl": "Te valideren", "fr": "A valider", "de": "Zu bestätigen"},
"D": {"nl": "Dubbels", "fr": "Doubles", "de": "Doppel"},
}

//----------------------------------------------------------------------------
function Header(props) {
  const authCtx = useContext(AuthContext);
  const phase = authCtx.phase; //TEST or PROD
  const lan = authCtx.language;
  const municipality_multi = {"nl": authCtx.Mnl, "fr": authCtx.Mfr, "de": authCtx.Mde}; 
  const Nis = authCtx.NIS;
  const email = authCtx.email;
  const page = authCtx.page;


  const hdr = hdr_multi[lan];
  const lblVersion = lblVersion_multi[lan];
  const lblUser = lblUser_multi[lan];
  var municipality = "";
  if (Nis) {
    municipality = municipality_multi[lan] + ' (' + Nis + ')'
    if (page) {
      municipality = municipality + ' : ' + page_multi[page][lan];
    }
  };

return (
    <div className="headerBar">
        <div className="grid-container">

        <div className="grid-child logo">
          <h1 className="heading">{hdr}</h1>
        </div>

        <div className="grid-child">
          <h1 className="heading title" >{municipality}</h1>
        </div> 

        <div className="grid-child">
          <table className="env" id="hdrTable">
            <tbody>
            <tr><td className="noborders bold underline">Info</td><td className="noborders"></td></tr>
            <tr>
              <td className="noborders">
                {lblVersion}
              </td>
              <td className="noborders">
                {phase}                
              </td>
            </tr>
            <tr>
              <td className="noborders">
                {lblUser}
              </td>
              <td className="noborders">
                {email}
              </td>
            </tr>
           </tbody>
          </table>
        </div>

      </div>

    </div>
  );
}
export default Header;