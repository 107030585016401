import React from "react";
import "./PrevIssue.css";

const lblIndex_multi = {'nl':'index : ', 'fr':'indice : ', 'de': 'Index : '};
const lblStep_multi = {'nl':'stap : ', 'fr':'pas : ', 'de': 'Schritt : '};

function PrevIssue(props) {
  const lan = props.language;
  const lblIndex = lblIndex_multi[lan];
  const lblStep = lblStep_multi[lan];
  const backward = "<==";

  return (
    <div id="prevIssue">
        <div id="divPrev">
          <button onClick={props.onClick}>{backward}</button>
        </div>
        <div>
          <table id="tabPrev"> 
          <tbody>
            <tr>
              <td id="lblIxPrev">{lblIndex}</td>
              <td>{props.ix}</td>
            </tr>
            <tr>
              <td id="lblStepBack">{lblStep}</td>
              <td><input type="text" id="stepBack" readOnly={true} value={props.step}/></td>
            </tr>
            </tbody>
          </table>
        </div>
    </div>
    );
}
export default PrevIssue;