import React from "react";
import "./SelectUI.css";
var hdr = {"nl":"Selectie van Problemen","fr":"Sélection des problèmes","de":"Auswahl der Probleme"};
var question1 = {"nl":"Selecteer open of behandelde problemen : ","fr":"Veuillez sélectionner les problèmes ouverts ou traités : ","de":"Bitte wählen Sie offene oder behandelte Probleme aus : "};
var question2 = {"nl":"Selecteer het type probleem: ","fr":"Veuillez sélectionner le type de problème: ","de":"Bitte wählen Sie die Art des Problems aus: "};
var lblOpen = {"nl":"Open","fr":"Ouvert","de":"Offen"};
var lblTreated = {"nl":"Behandeld","fr":"Traité","de":"Behandelt"};
var lblAll = {"nl":"Alle types","fr":"Tous les types","de":"Alle Typen"};
var lblB1 = {"nl":"B1. Straat is niet relevant : ","fr":"B1. Rue irrelevante : ","de":"B1. Straße irrelevant : "};
var lblB2 = {"nl":"B2. Straat mapping : ","fr":"B2. Mapping rue : ","de":"B2. Mapping der Straße : "};
var lblB3 = {"nl":"B3. Incoherente postcode : ","fr":"B3. Code postal incohérent : ","de":"B3. Inkohärente Postleitzahl : "};
var lblC1 = {"nl":"C1. Straat zonder huisnummers in BEST : ","fr":"C1. Rue sans numéros de maison dans BEST : ","de":"C1. Straße ohne Hausnummern in BEST : "};
var lblC2 = {"nl":"C2. Huisnummers verschillen : ","fr":"C2. Différents numéros de maison : ","de":"C2. Unterschiedliche Hausnummern : "};
var lblC3 = {"nl":"C3. Ambigue straten : ","fr":"C3. Rues ambiguës : ","de":"C3. Mehrdeutige Straßen : "};
var lblD1 = {"nl":"D1. Busnummer ontbreekt in BEST : ","fr":"D1. Numéro de boîte manquant dans BEST : ","de":"D1. Boxnummer fehlt in BEST : "};
var lblD2 = {"nl":"D2. Busnummers verschillen : ","fr":"D2. Différents numéros de boîte : ","de":"D2. Unterschiedliche Boxnummern : "};
var lblD3 = {"nl":"D3. Busnummer ontbreekt in de bron : ","fr":"D3. Le numéro de boîte est manquant dans la source : ","de":"D3. Die Boxnummer fehlt in der Quelle : "};
var lblZ2 = {"nl":"Z2. Tricky adressen : ","fr":"Z2. Tricky adresses : ","de":"Z2. Tricky Adressen : "};
var lblZ3 = {"nl":"Z3. Dubbele adressen : ","fr":"Z3. Adresses en double : ","de":"Z3. Doppelte Adressen : "};

function SelectUI(props) {
  const lan = props.language;
  return (
    <div className="selectUI">
      <div>
        <h1 className="heading">{hdr[lan]}</h1>
      </div>
      <div>
        <form>
          <p className="question">{question1[lan]}</p>
          <table>
            <tbody>
            <tr>
              <td><label htmlFor="open">{lblOpen[lan]}</label></td> 
              <td><input type="radio" id="open" name="openOrTreated" onChange={props.onFilter1} value="0" checked={props.filter1 === "0"}/></td>
            </tr>
            <tr>
              <td><label htmlFor="treated">{lblTreated[lan]}</label></td>
              <td><input type="radio" id="treated" name="openOrTreated" onChange={props.onFilter1} value="1" checked={props.filter1 === "1"}/></td>
            </tr>
            </tbody>
          </table>

          <p className="question">{question2[lan]}</p>
          <table>
          <tbody>
            <tr>
            <td><label htmlFor="all">{lblAll[lan]}</label></td>
            <td><input type="radio" id="all" name="warning" onChange={props.onFilter2} value="All" checked={props.filter2 === "All"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="B1">{lblB1[lan]}</label></td>
            <td><input type="radio" id="B1" name="warning" onChange={props.onFilter2} value="B1" checked={props.filter2 === "B1"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="B2">{lblB2[lan]}</label></td>
            <td><input type="radio" id="B2" name="warning" onChange={props.onFilter2} value="B2" checked={props.filter2 === "B2"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="B3">{lblB3[lan]}</label></td>
            <td><input type="radio" id="B3" name="warning" onChange={props.onFilter2} value="B3" checked={props.filter2 === "B3"}/></td>
            </tr>

            <tr>
            <td><label htmlFor="C1">{lblC1[lan]}</label></td>
            <td><input type="radio" id="C1" name="warning" onChange={props.onFilter2} value="C1" checked={props.filter2 === "C1"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="C2">{lblC2[lan]}</label></td>
            <td><input type="radio" id="C2" name="warning" onChange={props.onFilter2} value="C2" checked={props.filter2 === "C2"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="C3">{lblC3[lan]}</label></td>
            <td><input type="radio" id="C3" name="warning" onChange={props.onFilter2} value="C3" checked={props.filter2 === "C3"}/></td>
            </tr>

            <tr>
            <td><label htmlFor="D1">{lblD1[lan]}</label></td>
            <td><input type="radio" id="D1" name="warning" onChange={props.onFilter2} value="D1" checked={props.filter2 === "D1"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="D2">{lblD2[lan]}</label></td>
            <td><input type="radio" id="D2" name="warning" onChange={props.onFilter2} value="D2" checked={props.filter2 === "D2"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="D3">{lblD3[lan]}</label></td>
            <td><input type="radio" id="D3" name="warning" onChange={props.onFilter2} value="D3" checked={props.filter2 === "D3"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="Z2">{lblZ2[lan]}</label></td>
            <td><input type="radio" id="Z2" name="warning" onChange={props.onFilter2} value="Z2" checked={props.filter2 === "Z2"}/></td>
            </tr>
            <tr>
            <td><label htmlFor="Z3">{lblZ3[lan]}</label></td>
            <td><input type="radio" id="Z3" name="warning" onChange={props.onFilter2} value="Z3" checked={props.filter2 === "Z3"}/></td>
            </tr>

            </tbody>
          </table>
          <br/>
        </form>
      </div>
    </div>
    );
}
export default SelectUI;