import React from "react";

import "./Tricky.css";

//----------------------------------------------------------------------------
function Tricky(props) {

const acceptTricky = (evt) => {
  if (props.updStatus === 'accept') {
    props.changeTricky(props.ix, 'todo');
  } else {
  props.changeTricky(props.ix, 'accept');
  };
};

const rejectTricky = (evt) => {
  if (props.updStatus === 'reject') {
    props.changeTricky(props.ix, 'todo');
  } else {
    props.changeTricky(props.ix, 'reject');
  };
};

return (
  <tr>
    <td><label htmlFor="">{props.ix}</label></td>
    <td><label htmlFor="">{props.S + ' , ' + props.hs + ' , ' + props.bx}</label></td>
    <td><label htmlFor="">{props.S2 + ' , ' + props.hs2 + ' , ' + props.bx2}</label></td>
    <td><label htmlFor="">{props.idA}</label></td>
    <td><input type="checkbox" name="accept" checked={(props.updStatus === 'accept') ? true : false} onClick={acceptTricky}/></td>
    <td><input type="checkbox" name="reject" checked={(props.updStatus === 'reject') ? true : false} onClick={rejectTricky}/></td>
    <td><label htmlFor="">{props.updStatus}</label></td>
  </tr>
  );
}

export default Tricky;