import { useContext, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
import classes from './DownloadForm.module.css';

const lblDownloadMunInstructions_multi = 	{"nl":"TODOs voor de gemeente","fr":"TODOs pour la commune","de":"TODOs für die Gemeinde"};
const lblDownloadUserManual_multi = 	{"nl":"Gebruikershandleiding met FAQ","fr":"Guide d'utilisateur avec FAQ","de":"Benutzerhandbuch mit FAQ"};
const lblDownloadStats_multi = {"nl":"Statistieken","fr":"Statistiques","de":"Statistiken"};
const lblDownloadResult_multi = {"nl":"Resultaat","fr":"Résultat","de":"Ergebnis"};
const lblDownloadLog_multi = {"nl":"Logfile Server","fr":"Fichier journal Serveur", "de":"Logdatei Server"};
const lblDownloadLogMapping_multi = {"nl":"Logfile wekelijkse Mapping","fr":"Fichier journal Mapping hebdomadaire", "de":"Logdatei des wöchentlichen Mappings"};
const lblDownloadLogBatch_multi = {"nl":"Logfile Batch","fr":"Fichier journal Batch", "de":"Logdatei Batch"};
const lblDateLog_multi = {"nl":"Datum logfile (YYYYMMDD): ","fr":"Date fichier journal (YYYYMMDD): ", "de":"Datum-Logdatei (YYYYMMDD): "};
const alertDownloadNotAvailable_multi = {"nl": "Bestand nog niet beschikbaar - probeer het morgen opnieuw", "fr": "Fichier pas encore disponible - réessayez demain", "de": "Datei noch nicht verfügbar - versuchen Sie es morgen erneut"};

const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}

const DownloadForm = () => {
  const authCtx = useContext(AuthContext);
  const lan = authCtx.language;
  const idM = authCtx.idM;
  const isAdmin = authCtx.isAdmin;
  const domainBackend = authCtx.domainBackend;
  const objIdM = authCtx.NIS;
  const email = authCtx.email;
  const token = authCtx.token;
	const setPage = authCtx.setPage;
	setPage(""); //cfr Header.js

  const [YYYYMMDD, setYYYYMMDD] = useState(""); 

  const logDate = useRef();
  const logMappingDate = useRef();

  const lblDownloadMunInstructions = lblDownloadMunInstructions_multi[lan];
  const lblDownloadUserManual = lblDownloadUserManual_multi[lan];
  const lblDownloadStats = lblDownloadStats_multi[lan];
  const lblDownloadResult = lblDownloadResult_multi[lan];
  const lblDownloadLog = lblDownloadLog_multi[lan];
  const lblDownloadLogMapping = lblDownloadLogMapping_multi[lan];
  const lblDownloadLogBatch = lblDownloadLogBatch_multi[lan];
  const lblDateLog = lblDateLog_multi[lan];
  const alertDownloadNotAvailable = alertDownloadNotAvailable_multi[lan];

  const today = new Date();
  let strYYYYMMDD = today.toISOString().substring(0, 10); //result YYYY-MM-DD
  strYYYYMMDD = strYYYYMMDD.replace(/-/g, '');
  if (! YYYYMMDD) {
    setYYYYMMDD(strYYYYMMDD);
  };

  function downloadFile(addressURL, filename) {
    let authenticationString = createAuthenticationString(email, token);
    fetch(addressURL, {
      method: 'GET',
      headers: {
        'Authorization': authenticationString, 
        'Content-Type': 'text/plain'
        }
      })
      .then((response) => {
      console.log("response.status", response.status);		
      if (response.status === 200) {
          response.blob()
      .then((blob) => {
        
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          filename,
        );
      
        // Append to html link element page
        document.body.appendChild(link);
      
        // Start download
        link.click();
      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        });
      }
      else {
        alert(alertDownloadNotAvailable);
      }
    });
  };
  
  function downLoadMunInstructions() {
    downloadFile(domainBackend + '/download/mun?idM='+objIdM, objIdM+".xlsx");
  };	

  function downLoadUserManual() {
    downloadFile(domainBackend + '/download/usermanual?lan='+lan, "MunicipalityTool_UserManual_"+lan+".pdf");
  };	

  function downLoadStats() {
    downloadFile(domainBackend + '/download/stat', 'stat.xlsx');
  };

  function downLoadLog() {
    const YYYYMMDD = logDate.current.value;
    downloadFile(domainBackend + '/download/log?logDate='+YYYYMMDD, 'log_access_'+YYYYMMDD+'.txt');
  };

  function downLoadLogMapping() {
    const YYYYMMDD = logMappingDate.current.value;
    downloadFile(domainBackend + '/download/logMapping?logDate='+YYYYMMDD, 'log_mapping_'+YYYYMMDD+'.txt');
  };

 function changeLogDate(evt) {
   setYYYYMMDD(evt.target.value);
 };

  function downLoadLogBatch() {
    downloadFile(domainBackend + '/download/logBatch', 'log_batch.txt');
  };

  function downLoadResult() {
    downloadFile(domainBackend + '/download/result', 'result.txt');
  };

return (
  <section className={classes.auth}>
    <h1 class="heading">Download</h1>
    <div className={classes.actions}>
      <div>
        {(idM !== '') && (
          <div>
            <button onClick={downLoadMunInstructions}>{lblDownloadMunInstructions}</button>
            <p></p>
          </div>
        )}
      </div>
      <div>
        <button onClick={downLoadUserManual}>{lblDownloadUserManual}</button> 
      </div>
    </div>
    <br/>
    <div className={classes.actions}>
      {isAdmin && (
        <div>
          <div>
            <button onClick={downLoadStats}>{lblDownloadStats}</button> 
          </div>
          <br/>
          <div>
            <label htmlFor='logDate'>{lblDateLog}</label>
            {/* <input type='text' id='logDate' value={YYYYMMDD} size="8" ref={logDate} className={classes.yyyymmdd} onChange={changeLogDate}/> */}
            <input type='text' id='logDate' value={YYYYMMDD} size="8" ref={logDate} className={classes.yyyymmdd} onChange={changeLogDate}/>
            <button onClick={downLoadLog}>{lblDownloadLog}</button>  	
          </div>
          <br/>
          <div>
            <label htmlFor='logMappingDate'>{lblDateLog}</label>
            {/* <input type='text' id='logDate' value={YYYYMMDD} size="8" ref={logDate} className={classes.yyyymmdd} onChange={changeLogDate}/> */}
            <input type='text' id='logMappingDate' value={YYYYMMDD} size="8" ref={logMappingDate} className={classes.yyyymmdd} onChange={changeLogDate}/>
            <button onClick={downLoadLogMapping}>{lblDownloadLogMapping}</button>  	
          </div>
          <br/>
          <div>
            <button onClick={downLoadLogBatch}>{lblDownloadLogBatch}</button>  
          </div>
          <br/>
          <div>
            <button onClick={downLoadResult}>{lblDownloadResult}</button>  
          </div>
        </div>
      )}
    </div>
  </section>
  );
};

export default DownloadForm;