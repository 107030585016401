import { Fragment } from "react";
import Header from '../components/UI/Header';
import LoginForm from '../components/Auth/LoginForm';

const LoginPage = () => {
  return (
    <Fragment>
     <Header/>
     <LoginForm />
   </Fragment>); 
};

export default LoginPage;
