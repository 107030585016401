import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
//import LoadMunicipalityData from './LoadMunicipalityData';

import Password from "./Password";
import classes from './ChangePasswordForm.module.css';

const msgPasswordChanged_multi = {'nl':'Paswoord is gewijzigd.', 'fr':'Le mot de passe a été modifié.', 'de':'Das Passwort wurde geändert.'};
const msgInitialPasswordChanged_multi = {'nl':'Initieel paswoord is gewijzigd.', 'fr':'Le mot de passe initial a été modifié.', 'de':'Das Initialpasswort wurde geändert.'};
const instruction_multi = {'nl':'Eerste gebruik: wijzig het initieel paswoord aub', 'fr':'Première utilisation: changez le mot de passe initial svp', 'de':'Erstmalige Verwendung: Bitte ändern Sie das anfängliche Passwort'};
const lblOldPassword_multi = {'nl':'Oud paswoord: ', 'fr':'Ancien mot de passe: ', 'de':'Altes Passwort: '};
const lblNewPassword_multi = {'nl':'Nieuw paswoord: ', 'fr':'Nouveau mot de passe: ', 'de':'Neues Passwort: '};

const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}

const ChangePasswordForm = () => {
  const authCtx = useContext(AuthContext);
  const lan = authCtx.language;
  const email = authCtx.email;
  const domainBackend = authCtx.domainBackend;
  const isAdmin = authCtx.isAdmin;
  const isFirstTime = authCtx.isFirstTime;
  const logout = authCtx.logout;
	const setPage = authCtx.setPage;
	setPage(""); //cfr Header.js

  // const oldPassword = useRef();
  // const newPassword = useRef();
  const [oldPassword, setOldPassword] = useState("");
  const oldPasswordHandler = (pswrd) => {
      setOldPassword(pswrd);
      //console.log("old password", pswrd);
  };
  const [newPassword, setNewPassword] = useState("");
  const newPasswordHandler = (pswrd) => {
      setNewPassword(pswrd);
      //console.log("new password", pswrd);
  };

  const history = useHistory();

  const[warning, setWarning] = useState({});
  //const [fetchMunicipalityData, setFetchMunicipalityData] = useState(false);

  const msgPasswordChanged = msgPasswordChanged_multi[lan];
  const msgInitialPasswordChanged = msgInitialPasswordChanged_multi[lan];
  const instruction = instruction_multi[lan];
  const lblOldPassword = lblOldPassword_multi[lan];
  const lblNewPassword = lblNewPassword_multi[lan];

  console.log("RENDER CHANGEPASSWORD");
  
  const handleChangePassword = async (event) => {
    event.preventDefault();
    console.log("handleChangePassword");
    if ((! oldPassword) || (! newPassword)) {
      setWarning({
        "nl": "Vul in oud en nieuw paswoord",
        "fr": "Remplissez ancien et nouveau mot de passe",
        "de": "Altes und neues Passwort eingeben",
      });
      return;
    }
     try {
        console.log("ChangePassword: communicate with server");
        let authenticationString = createAuthenticationString(email, oldPassword);
        const requestOptions = {
          method: 'POST',
          headers: { 
              'Authorization': authenticationString, 
              'Content-Type': 'application/json' 
          },
          body: JSON.stringify({"newPassword": newPassword})
        };
        const response = await fetch(domainBackend + '/changePassword', requestOptions);
        const resData = await response.json();
        console.log(resData);

        const msg = resData.msg;

        if (msg ==='password is changed') { 
          setWarning({
            "nl": "",
            "fr": "",
            "de": "",       
          });
          alert(msgPasswordChanged);

          console.log(" ChangePassword: history");
          if (isAdmin) {
            history.replace('/municipalities'); //admin has to select the municipality
          } else {
            history.replace('/issues');
          };

          return;
        }

        //part of first time use
        if (msg === 'logon successful') {
          console.log("ChangePassword - first time use - logon successful");
          authCtx.handleIsFirstTime(false); //quit first time use procedure
          const token = resData.token;
          const NIS = resData.NIS;
          const idM = resData.idM;
          const Mnl = resData.Mnl;
          const Mfr = resData.Mfr;
          const Mde = resData.Mde;
          const isAdmin = ((NIS === 'BOSA') ? true : false );

          alert(msgInitialPasswordChanged);

          console.log("ChangePassword - first time use - logon successful: setting authCtx.login");
          authCtx.login(token, email, isAdmin, NIS, idM, Mnl, Mfr, Mde);

          console.log("ChangePassword - first time use - logon successful: authCtx.handleIsFirstTime");
          authCtx.handleIsFirstTime(false);
          logout();
          return;
        };

      if (msg ==='wrong password' ) {
        setWarning({
          "nl": "Verkeerd paswoord",
          "fr": "Mauvais mot de passe",
          "de": "Falsches Passwort",       
        });
        return;
      };
      if (msg ==='technical issue at login' ) {
        setWarning({
          "nl": "Probleem in ChangePassword",
          "fr": "Problème dans ChangePassword",
          "de": "Problem in ChangePassword",       
        });
        return;
      };

    } catch (err) {
      setWarning({
        "nl": "Probleem in handleChangePassword()",
        "fr": "Problème dans handleChangePassword()",
        "de": "Problem in handleChangePassword()",       
      });
    }
  };
//----------------------------------------------------------------------

  return (
    <section className={classes.auth}>
      <form onSubmit={handleChangePassword}>
        <div className={classes.control}>
          {isFirstTime && <p>{instruction}</p>}
        </div>
        <Password
          lblPassword = {lblOldPassword}
          autoFocus = {true}
          onChangePassword = {oldPasswordHandler}
        /> 
        <Password
          lblPassword = {lblNewPassword}
          autoFocus = {false}
          onChangePassword = {newPasswordHandler}
        />  
        <div className={classes.actions}>
          <button>Submit</button>
        </div>
      </form>
      <p>{warning[lan]}</p>
    </section>
  );
};

export default ChangePasswordForm;