import React from "react";
import "./NextIssue.css";

const lblIndex_multi = {'nl':'index : ', 'fr':'indice : ', 'de': 'Index : '};
const lblStep_multi = {'nl':'stap : ', 'fr':'pas : ', 'de': 'Schritt : '};

function NextIssue(props) {
  const lan = props.language;
  const lblIndex = lblIndex_multi[lan];
  const lblStep = lblStep_multi[lan];
  const forward = "==>";
  
  return (

    <div id="nextIssue">
      <div id="divNext">
        <button onClick={props.onClick}>{forward}</button> 
      </div>
      <div>
        <table id="tabNext">
          <tbody>
          <tr>
            <td  id="lblIx">{lblIndex}</td>
            <td>{props.ix}</td>
          </tr>
          <tr>
            <td id="lblStep">{lblStep}</td>
            <td><input type="text" id="step" onChange={props.onStepChange} value={props.step}/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    );
}
export default NextIssue;