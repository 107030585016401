import { Fragment } from "react";
import Header from '../components/UI/Header';
import Doubles from '../components/UI/Doubles';

const DoublesPage = () => {
  return (
    <Fragment>
     <Header/>
     <Doubles />
   </Fragment>); 
};

export default DoublesPage;
