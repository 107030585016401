import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";

import "./Doubles.css";
import DoublesForIdA from "./DoublesForIdA";

const statMsg1_multi = {"nl":"BEST adressen met dubbels", "fr":"adresses BEST avec doubles", "de":"BEST Adressen mit Doppel"};
const statMsg2_multi = {"nl":"Rijksregister adressen", "fr":"adresses du Registre National", "de":"Adressen des Nationalregisters"};
const lblButtonUpdate_multi = {"nl":"Update","fr":"Update","de":"Update"};
const updMessage_multi = {"nl":"Verworpen mappings kunnen manueel worden gemapped in het Issues-scherm.", "fr":"Les mappings rejetés peuvent être mappés manuellement dans l'écran 'Issues'.","de":"Abgelehnte Mappings können im Bildschirm 'Issues' manuell zugeordnet werden."};
const adminCannotUpdateMsg_multi = {"nl":"Een admin gebruiker kan niet bijwerken.", "fr":"Un utilisateur admin ne peut pas mettre à jour.","de":"Ein Admin-Benutzer kann nicht aktualisieren."};
//const concurrencyMsg_multi = {"nl": "Het probleem is eerder vandaag bijgewerkt door ", "fr": "Le problème a été mis à jour plus tôt aujourd'hui par ", "de": "Das Problem wurde früher heute aktualisiert von "};
const lblIdA_multi = {"nl":"Adres id", "fr":"Id adresse", "de":"Id Adresse"};
const lblPostcode_multi = {"nl":"Postcode", "fr":"Code postal", "de":"Postleitzahl"};
const lblStreetCode_multi = {"nl":"Straatcode RR", "fr":"Code rue RN", "de":"Straßencode RR"};
const lblStreet_multi = {"nl":"Straat", "fr":"Rue", "de":"Straße"};
const lblHs_multi = {"nl":"Huisnummer", "fr":"Numéro de la maison", "de":"Hausnummer"};
const lblBx_multi = {"nl":"Bus", "fr":"Boîte", "de":"Bus"};
const lblKeep_multi = {"nl":"Te bewaren adres", "fr":"Adresse à conserver", "de":"Adresse zu behalten"};
const lblReject_multi = {"nl":"Mapping niet correct", "fr":"Mapping incorrect", "de":"Mapping nicht korrekt"};
const lblTodo_multi = {"nl":"Todo bijwerking", "fr":"Todo màj", "de":"Todo-Update"};
const lblWait_multi = {"nl":" Even wachten aub", "fr":" Patientez quelques instants svp", "de":" Warten Sie mal"};
const howToUseMsg1_multi = {
 "nl": "Normaal volstaat het, enkel de radio button onder 'Te bewaren adres' te gebruiken om dubbels te behandelen." ,
 "fr": "Normalement, il suffit d'utiliser uniquement le bouton radio sous 'Adresse à conserver' pour traiter les doublons.",
 "de": "Normalerweise reicht es aus, nur das Optionsfeld unter 'Adresse zu behalten' zu verwenden, um mit Doubles umzugehen."
};
const howToUseMsg2_multi = {
  "nl": "Eerder uitzonderlijk, gebruik je de check box onder 'Mapping niet correct': enkel wanneer het RRN adres niet overeenstemt met het BEST adres; " +
  "in dat geval dien je, na de update, in het scherm 'Issues' het verworpen adres manueel te mappen op een ander BEST adres. " +
  "Doe je dit niet voor het volgend weekend, dan zal je de dubbels de week erop opnieuw moeten behandelen in dit scherm.",
  "fr": "Plutôt exceptionnellement, vous utilisez la case à cocher sous 'Mapping incorrect' : uniquement si l'adresse RRN ne correspond pas à l'adresse BEST ; " +
  "dans ce cas, après l'update, vous devez mapper manuellement l'adresse rejetée à une autre adresse BEST dans l'écran 'Issues'. " +
  "Si vous ne le faites pas avant le week-end prochain, vous devrez à nouveau gérer les doubles sur cet écran la semaine suivante.",
  "de": "Verwenden Sie eher ausnahmsweise das Kontrollkästchen unter 'Mapping nicht korrekt': nur wenn die RRN-Adresse nicht mit der BEST-Adresse übereinstimmt; "+
  "in diesem Fall müssen Sie nach dem Update die abgelehnte Adresse manuell einer anderen BEST-Adresse im Bildschirm 'Issues' zuordnen. " +
  "Wenn Sie dies nicht vor dem nächsten Wochenende tun, müssen Sie sich in der folgenden Woche in diesem Bildschirm erneut mit den Doppeln auseinandersetzen."
 };
 const howToUseMsg3_multi = {
  "nl": "Je kan meer gevallen op het scherm behandelen en vervolgens een update te doen. Bijwerkingen gebeuren pas na klikken op de 'Update' knop. " +
  "In de kolom 'Todo bijwerking' zie je welke updates zullen gebeuren in functie van je gemaakte keuzes.",
  "fr": "Vous pouvez traiter plus de cas à l'écran, puis faire un update. Les mises à jour ne se produisent qu'après avoir cliqué sur le bouton 'Update'." +
  "Dans la colonne 'Todo màj', vous pouvez voir quelles mises à jour se produiront en fonction de vos choix.",
  "de": "Sie können mehr Fälle auf dem Bildschirm bearbeiten und dann ein Update durchführen. Aktualisierungen treten erst auf, nachdem Sie auf die Schaltfläche 'Update' geklickt haben." +
  "In der Spalte 'Todo-Update' können Sie sehen, welche Updates in Abhängigkeit von Ihrer Auswahl erfolgen."
 };
  
const createAuthenticationString = (uid, token) => {
  let authString = uid + ':' + token;
  let buff = new Buffer.from(authString, 'utf8');
  let authString64 = buff.toString('base64');
  let auth64 = 'Basic ' + authString64;
  //console.log(authString, authString64, auth64); 
  return auth64 ;
}
// const getShorterId = id => {
//   if (id.slice(0,1) !== "F") {
//     return id;
//   };
//   let arr = id.split('_');
//   return arr[0] + '_' + arr[1];
// }
//----------------------------------------------------------------------------
function Doubles(props) {
const authCtx = useContext(AuthContext);
const lan = authCtx.language;
const domainBackend = authCtx.domainBackend;
const email = authCtx.email;
const token = authCtx.token;
const isAdmin = authCtx.isAdmin;
const issues = authCtx.issues;
const updDoubleInContext = authCtx.updDoubleInContext;
const allDoubles = authCtx.doubles;
const updDoublesForIdA = authCtx.updDoublesForIdA;
const refreshDoubles = authCtx.refreshDoubles;
const blockUpdates = authCtx.isInIncidentState || isAdmin;
const setPage = authCtx.setPage;
setPage("D"); //cfr Header.js

const [lstToUpdate, setLstToUpdate] = useState([]); //contains issues to update
const [stepUpdate, setStepUpdate] = useState("idle"); // idle => updating => showResult => refresh => idle
const [statUpdate, setStatUpdate] = useState({'cntOK': 0, 'lstOK': [], 'cntNOK': 0, 'lstNOK': []});
const [isBusy, setIsBusy] = useState(false);

const statMsg1 = statMsg1_multi[lan];
const statMsg2 = statMsg2_multi[lan];
const lblButtonUpdate = lblButtonUpdate_multi[lan];
const updMessage = updMessage_multi[lan];
const adminCannotUpdateMsg = adminCannotUpdateMsg_multi[lan];
//const concurrencyMsg = concurrencyMsg_multi[lan];
const lblIdA = lblIdA_multi[lan];
const lblPostcode = lblPostcode_multi[lan];
const lblStreetCode = lblStreetCode_multi[lan];
const lblStreet = lblStreet_multi[lan];
const lblHs = lblHs_multi[lan];
const lblBx = lblBx_multi[lan];
const lblKeep = lblKeep_multi[lan];
const lblReject = lblReject_multi[lan];
const lblTodo = lblTodo_multi[lan];
const lblWait = lblWait_multi[lan];
const howToUseMsg1 = howToUseMsg1_multi[lan];
const howToUseMsg2 = howToUseMsg2_multi[lan];
const howToUseMsg3 = howToUseMsg3_multi[lan];

const authenticationString = createAuthenticationString(email, token);

const numberOfIdAs = Object.keys(allDoubles).length;

//----------------------------------------------------------------------------
var numberOfIssues = 0;
for (const lst of Object.values(allDoubles)) {
  numberOfIssues = numberOfIssues + lst.length;
};

//let i = 0;
//let maxIdA = 10;
const doubles = {};
for (const [idA, lstDoublesForIdA] of Object.entries(allDoubles)) {
  //if (i < maxIdA) {
    doubles[idA] = lstDoublesForIdA;
  //};
  //i++;
};
//----------------------------------------------------------------------------
const updateDoubles = () => {
  console.log("updateDoubles");
  if (isAdmin) {
    alert(adminCannotUpdateMsg);
    return;
  };
  if (isBusy) {
    return;
  };
  const lst = [];
  var cntUpdates = 0;
  for (const lstDoublesForIdA of Object.values(doubles)) {
    for (var ix = 0; ix < lstDoublesForIdA.length; ix++) { 
      const double = lstDoublesForIdA[ix];
      const updStatus = double['treated'];
      if (['reject mapping', 'reject double', 'accept double'].includes(updStatus)) {
        const ixIssue = double.ixIssue;
        const issue = issues[ixIssue];
        issue.warning = "Z3";
        if (double.treated === 'accept double') {
          issue.action = "04";
          issue.treated = "1";
        };
        if (double.treated === 'reject double') {
          issue.action = "05";
          issue.treated = "1";
        };
        if (double.treated === 'reject mapping') {
          issue.action = "19";
          issue.treated = "0";
        };
        lst.push(issue);
        cntUpdates ++;
      };
    };
  };
  if (cntUpdates > 0) {
    setLstToUpdate(lst);  
    console.log("lst to update", lst);  
    setStepUpdate("updating");
    setIsBusy(false);
  };
};
//----------------------------------------------------------------------------------
useEffect(function stepUpdating() {
  async function updateRecord(issue, lst) {
    console.log("UPDATING", issue);
    let stat = JSON.parse(JSON.stringify(statUpdate));

    try {
      const requestOptions = {
      method: 'PUT',
      headers: { 
        'Authorization': authenticationString,
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify({'issue':issue})
      };

      console.log("requestOptions:", requestOptions);

      const response = await fetch(domainBackend + '/issue', requestOptions);
      const resData = await response.json();
      console.log("resData", resData);
      //'update OK', 'user unknown', 'token invalid', 'token expired - pls login again', 'invalid idM'
      if (resData.msg === 'Issue was updated earlier today') {
        console.log("Update failed - earlier update from another user");
        //let alertMsg = concurrencyMsg + resData.colleague + ' - timestamp (UTC): ' + resData.issue.upd + ', issue: ' + issue._id;
        //console.log(alertMsg);
        updDoubleInContext(resData.issue);
      };

      if (resData.msg === 'update OK') {
        issue.upd = resData.upd; //add timestamp update by server
        issue.user = email;
        console.log("update OK", issue.idA, issue._id, issue.upd);
        stat.cntOK++;
        stat.lstOK.push(issue._id);
        setStatUpdate(stat);
        updDoubleInContext(issue);
      } else {
          alert(resData.msg);
          stat.cntNOK++;
          stat.lstNOK.push(issue._id);
          setStatUpdate(stat);
      };
    } catch (err) {
      alert("Update problem in updateRecord(double)");
      stat.cntNOK++;
      stat.lstNOK.push(issue._id);
      setStatUpdate(stat);
    };
    setIsBusy(false); //you can now go to the next update or to step showResult
  }; //end of async function definition updateRecord

  console.log("useEffect stepUpdating"); //here useEffect really starts
  console.log(stepUpdate, isBusy);
  if (stepUpdate !== "updating" || isBusy) {
    return;
  };
  setIsBusy(true); //prevent useEffect to treat the next update or to show the result counters of the batch update in step showResult
  let lst = JSON.parse(JSON.stringify(lstToUpdate));
  const issue = lst.shift(); // shift() removes the first item of the list

  updateRecord(issue, lst); //async part of 1 update - the next line of code is immediately executed after the launch of the async function

  setLstToUpdate(lst);
  if (lst.length === 0) {
    setStepUpdate("showResult");
  };
}  //end of function
, [stepUpdate, lstToUpdate, setLstToUpdate, setStepUpdate, statUpdate, setStatUpdate, authenticationString, domainBackend, updDoubleInContext, isBusy, email] //first param is function, if second param changes it triggers useEffect to be called (besides being called at mount/unmount)
); //end of useEffect	
//----------------------------------------------------------------------
useEffect(function stepShowResult() {
  console.log("useEffect stepShowResult");
  if (stepUpdate !== "showResult" || isBusy) {
      return;
  };
  //do your stuff
  console.log("useEffect stepShowResult - do the real work");
  const alertMessage = "Update OK: " + statUpdate.cntOK + "\nUpdate NOK: " + statUpdate.cntNOK + "\n" + updMessage + "\n\nOK: " + statUpdate.lstOK +  "\n\nNOK: " + statUpdate.lstNOK;
  alert(alertMessage);

  //go to next step
  setStepUpdate("refresh"); //updating phase has ended
  setStatUpdate({'cntOK': 0, 'lstOK': [], 'cntNOK': 0, 'lstNOK': []});
}  //end of function
, [stepUpdate, statUpdate, updMessage, isBusy, setStatUpdate, refreshDoubles] //first param is function, if second param changes it triggers useEffect to be called (besides being called at mount/unmount)
); //end of useEffect	
//----------------------------------------------------------------------
useEffect(function refresh() {
  console.log("useEffect refresh");
  if (stepUpdate !== "refresh" || isBusy) {
      return;
  };
  //do your stuff
  console.log("useEffect refresh - do the real work");
  refreshDoubles();

  //go to next step
  setStepUpdate("idle"); //updating phase has ended
}  //end of function
, [stepUpdate, isBusy, refreshDoubles, setStepUpdate] //first param is function, if second param changes it triggers useEffect to be called (besides being called at mount/unmount)
); //end of useEffect	
//----------------------------------------------------------------------------
return (
  <div>
    <p>{`${numberOfIdAs} ${statMsg1} (${numberOfIssues} ${statMsg2}`})</p>
    <p>{howToUseMsg1}</p>
    <p>{howToUseMsg2}</p>
    <p>{howToUseMsg3}</p>
    <button disabled={blockUpdates} onClick={updateDoubles}>{lblButtonUpdate}</button> 
    <label htmlFor="" className="warningMsg">{stepUpdate === 'updating' ? lblWait : ''}</label>
    <br/><br/> 

    <section className="gridDoubles" >
          <label htmlFor="" className="box-double-idA double-header">{lblIdA}</label>
          <label htmlFor="" className="box-double-postcode double-header">{lblPostcode}</label>
          <label htmlFor="" className="box-double-streetcode double-header">{lblStreetCode}</label>
          <label htmlFor="" className="box-double-street double-header">{lblStreet}</label>
          <label htmlFor="" className="box-double-hs double-header">{lblHs}</label>
          <label htmlFor="" className="box-double-bx double-header">{lblBx}</label>
          <label htmlFor="" className="box-double-radioButton double-header">{lblKeep}</label>
          <label htmlFor="" className="box-double-checkBox double-header">{lblReject}</label>
          <label htmlFor="" className="box-double-updStatus double-header">{lblTodo}</label>
    </section>
    <section>
        { Object.entries(doubles).map( ([idA, lstDoublesForIdA]) => {
            return (
                  <DoublesForIdA
                    key = {idA}
                    lan = {lan}
                    idA = {idA}
                    lstDoublesForIdA = {lstDoublesForIdA}
                    Snl2 = {lstDoublesForIdA[0]['Snl2']}
                    Sfr2 = {lstDoublesForIdA[0]['Sfr2']}
                    Sde2 = {lstDoublesForIdA[0]['Sde2']}
                    hs2 = {lstDoublesForIdA[0]['hs2']}
                    bx2 = {lstDoublesForIdA[0]['bx2']}
                    P2 = {lstDoublesForIdA[0]['P2']}
                    idS = {lstDoublesForIdA[0]['idS']}
                    updDoublesForIdA = {updDoublesForIdA}
                  />
              )
            })
        }
    </section>
  </div>
  );
}
export default Doubles;
