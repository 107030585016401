import { useState, useRef, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";

import classes from './LoginForm.module.css';
import Password from "./Password";
const lblEmail_multi = {'nl': 'e-mail adres: ', 'fr':'Adresse e-mail: ', 'de':'E-Mail-Addresse: '};
const lblPassword_multi = {'nl':'Paswoord: ', 'fr':'Mot de passe: ', 'de':'Passwort: '};

const createAuthenticationString = (uid, token) => {
    let authString = uid + ':' + token;
    let buff = new Buffer.from(authString, 'utf8');
    let authString64 = buff.toString('base64');
    let auth64 = 'Basic ' + authString64;
    //console.log(authString, authString64, auth64); 
    return auth64 ;
}

const LoginForm = () => {
    const authCtx = useContext(AuthContext);
    const lan = authCtx.language;
    const domainBackend = authCtx.domainBackend;
	const setPage = authCtx.setPage;
	setPage(""); //cfr Header.js
    const fetchIssues = authCtx.fetchIssues;
    const fetchStreetnames = authCtx.fetchStreetnames;
    const fetchNumbers = authCtx.fetchNumbers;
    const isAdmin = authCtx.isAdmin;
    const NIS = authCtx.NIS;
 
    const email = useRef();
    //const password = useRef();
    const [password, setPassword] = useState("");
    const passwordHandler = (pswrd) => {
        setPassword(pswrd);
        //console.log("password", pswrd);
    };

    const history = useHistory();

    const[warning, setWarning] = useState({});
    const [newNIS, setNewNIS] = useState(false);

    const lblEmail = lblEmail_multi[lan];
    const lblPassword = lblPassword_multi[lan];

    console.log("RENDER LoginForm.js - email: ", email);


//-------------------------------------------------------------------------
    const loginHandler = async (event) => {
        event.preventDefault();
        console.log("loginHandler");
        if (! (email.current.value && password)) {
            setWarning({
            "nl": "Vul in email en paswoord",
            "fr": "Remplissez email et mot de passe",
            "de": "E-Mail und Passwort eingeben",
            });
            return;
        }
        try {
            let authenticationString = createAuthenticationString(email.current.value, password);
            const requestOptions = {
                method: 'POST',
                headers: { 
                    'Authorization': authenticationString, 
                    'Content-Type': 'application/json' 
                }
                //body: JSON.stringify({"email": email.current.value})
            };
            console.log(requestOptions.headers);
            const response = await fetch(domainBackend + '/login', requestOptions);
            const resData = await response.json();
            console.log(resData);

            const msg = resData.msg;
            console.log("loginHandler: msg", msg);
            if (msg === 'logon successful') {
                setWarning({
                    "nl": "",
                    "fr": "",
                    "de": "",       
                });
                const token = resData.token;
                const Nis = resData.NIS;
                const idM = resData.idM;
                const Mnl = resData.Mnl;
                const Mfr = resData.Mfr;
                const Mde = resData.Mde;
                const isAdmin = ((Nis === 'BOSA') ? true : false );
                console.log("authCtx.login", Nis);
                authCtx.login(token, email.current.value, isAdmin, Nis, idM, Mnl, Mfr, Mde);
                //console.log("===", token, email.current.value, isAdmin, NIS, idM, Mnl, Mfr, Mde);

                if (isAdmin) {
                    console.log("loginhandler: history to /municipalities");
                    history.replace('/municipalities');
                } else {
                    setNewNIS(true);
                    fetchIssues(Nis, email.current.value, token);
                    fetchStreetnames(idM, email.current.value, token);
                    fetchNumbers(idM, email.current.value, token);
                };
                return;
            };
            if (msg ==='change password' ) {
                authCtx.handleIsFirstTime(true);
                authCtx.login("", email.current.value, false, "", "", "", "", "");
                console.log("loginhandler: history to /changePassword");
                history.replace('/changePassword');
                return;
            };
            if (msg ==='user does not exist' ) {
                setWarning({
                    "nl": "Gebruiker bestaat niet",
                    "fr": "L'utilisateur n'existe pas",
                    "de": "Benutzer existiert nicht",       
                });
                return;
            };
            if (msg ==='wrong initial password' ) {
                setWarning({
                    "nl": "Verkeerd initieel paswoord",
                    "fr": "Mauvais mot de passe initial",
                    "de": "Falsches Anfangspasswort",       
                });
                return;
            };
            if (msg ==='wrong password' ) {
                setWarning({
                    "nl": "Verkeerd paswoord",
                    "fr": "Mauvais mot de passe",
                    "de": "Falsches Passwort",       
                });
                return;
            };
            if (msg ==='technical issue at login' ) {
                setWarning({
                    "nl": "Probleem in Login",
                    "fr": "Problème dans Login",
                    "de": "Problem in Login",       
                });
                return;
            };
        } catch (err) {
            setWarning({
            "nl": "Probleem in loginHandler()",
            "fr": "Problème dans loginHandler()",
            "de": "Problem in loginHandler()",       
            });
        }
    }; 
//------------------------------------------------------------------------------------
useEffect(() => {
    if (isAdmin) {
        return
    };
    if (NIS  && NIS !== 'BOSA' && newNIS) {
        setNewNIS(false);
        history.replace('/issues');
    }
}       
    , [NIS, isAdmin, history, newNIS]    //first param is function, second param is dependency (when changed, this useEffect is called) (also called at mount/unmount now)
);        //end of useEffect
//------------------------------------------------------------------------------------
    return (
    <section className={classes.auth}>
        <form onSubmit={loginHandler}>
            <div className={classes.control}>
                <label htmlFor='email'>{lblEmail}</label>
                <input type='email' id='email' required autoFocus ref={email}/>
            </div>
            <Password
                lblPassword = {lblPassword}
                onChangePassword = {passwordHandler}
                autoFocus = {false}
            />
            <div className={classes.actions}>
                <button>Login</button>
            </div>
        </form>
        <p>{warning[lan]}</p>
    </section>
    );
};

export default LoginForm;