import React, { useContext } from "react";
import AuthContext from "../../store/auth-context";

import "./Mapping.css";
import Postcode from "../best/Postcode.js";
import Streetname from "../best/Streetname.js";
import HouseNumber from "../best/HouseNumber.js";
import BoxNumber from "../best/BoxNumber.js";

const hdr_multi = {"nl":"BEST adres (Mapping door de gemeente) :","fr":"Adresse BEST (Mapping par la commune) :","de":"BEST Adresse (Mapping von der Gemeinde) :"};
const lblMunicipality_multi = {"nl":"Gemeente: ","fr":"Commune: ","de":"Gemeinde: "};
const lblPostcodes_multi  = {"nl":"Kies postcode: ","fr":"Choisissez le code postal: ","de":"Wählen Sie die Postleitzahl: "};
const lblStreets_multi  = {"nl":"Kies straat: ","fr":"Choisissez la rue: ","de":"Wählen Sie die Straße: "};
const lblNumbers_multi  = {"nl":"Kies nummer: ","fr":"Choisissez le numéro: ","de":"Wählen Sie die Nummer: "};
const lblBoxes_multi  = {"nl":"Kies bus: ","fr":"Choisissez la boîte: ","de":"Wählen Sie den Bus: "};
const lblIdA_multi  = {"nl":"id BEST Adres: ","fr":"id Adresse BEST: ","de":"id BEST Adresse: "};

function Mapping(props) {
  const authCtx = useContext(AuthContext);
  const lan = authCtx.language;
  const municipality_multi = {"nl":authCtx.Mnl, "fr":authCtx.Mfr, "de":authCtx.Mde};

  const municipality = municipality_multi[lan];
  const hdr = hdr_multi[lan];
  const lblMunicipality = lblMunicipality_multi[lan];
  const lblPostcodes  = lblPostcodes_multi[lan];
  const lblStreets  = lblStreets_multi[lan];
  const lblNumbers  = lblNumbers_multi[lan];
  const lblBoxes  = lblBoxes_multi[lan];
  const lblIdA  = lblIdA_multi[lan];

  var mapStreetnames = [];
  if (lan === 'nl') {
    mapStreetnames = props.mapStreetnamesNL;
  };
  if (lan === 'fr') {
    mapStreetnames = props.mapStreetnamesFR;
  };
  if (lan === 'de') {
    mapStreetnames = props.mapStreetnamesDE;
  };
  //console.log(props.mapidS);
  //console.log(mapStreetnames);
  console.log('$$$$$ ', props.mapIdA);
//----------------------------------------------------------------------------------
	return (
	<div>
		<h3 className="mappingTitle">{hdr}</h3>
    <table>
    <tbody>
      <tr>
        <td><label htmlFor="mapM">{lblMunicipality}</label></td>
        <td><input type="text" name="mapM" id="mapM" readOnly={true} value={municipality}/></td>
      </tr>

      <tr>
        <td><label htmlFor="lstPostcodes">{lblPostcodes}</label></td>
        <td><select name="postcodes" id="lstPostcodes" onChange={props.onMapP} value={props.mapP}>
        {props.mapPs.map(P => {
          return (
              <Postcode
                P={P} 
              />
            )
          })
        }
        </select></td>
      </tr>
      <tr>
        <td><label htmlFor="lstStreets">{lblStreets}</label></td>
        <td><select name="streets" id="lstStreets" onChange={props.onMapIdS} value={props.mapIdS}>
        {mapStreetnames.map((item) => {
          return (
                <Streetname
                name={item[0]}
                idS={item[1]} 
              />
              )
            })
        }
        </select></td>
      </tr>

      <tr>
        <td><label htmlFor="lstNumbers">{lblNumbers}</label></td>
        <td><select name="numbers" id="lstNumbers" onChange={props.onMapHs} value={props.mapHs}>
        {props.mapStreetHouseNumbers.map(hs => {
              return (
                <HouseNumber
                hs={hs} 
              />
              );
            }) 
          }

        </select></td>
      </tr>

      <tr>
        <td><label htmlFor="lstBoxes">{lblBoxes}</label></td>
        <td><select name="boxes" id="lstBoxes" onChange={props.onMapBx} value={props.mapBx}>
        {props.mapBoxNumbers.map(bx => {
              return (
                <BoxNumber
                bx={bx} 
              />
              );
            }) 
        }
        </select></td>
      </tr>
      </tbody>
      </table>
      <label className="warningMsg">{props.mapWarning}</label>
      <br/>
      <label htmlFor="idA">{lblIdA}</label>
      <input type="text" name="idA" className="id" readOnly={true} value={props.mapIdA}/>
	  <br/><br/>
	</div>
	);
}
export default Mapping;